import { FETCH_SCHOOL, SCHOOL_LOADED, LOAD_SCHOOL } from '../../constants/actionTypes'

export default (school = { school: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_SCHOOL:
            return { ...school, school: action.payload, loading: false };
        case LOAD_SCHOOL:
            return { ...school, loading: true };
        case SCHOOL_LOADED:
            return { ...school, loading: false };
        default:
            return school;
    }
}