import React from 'react'
import { useTranslation } from 'react-i18next'

const Grow = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    return (
        <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }} className="grow-business pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="grow-text">
                            <div className="section-title">
                                <h2>{t("Grow Your Business Faster With Premium Advertising")}</h2>
                            </div>
                            <div>
                                <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus")}</p>
                            </div>
                            <div>
                                <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus")}</p>
                            </div>
                            <div>
                                <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus")}</p>
                            </div>
                            <div className="theme-btn">
                                <a className="default-btn">{t("Checkout More")}</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="grow-img">
                            <img src={require("../../../assets/img/about-us/grow-img.jpeg")} alt="grow image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Grow