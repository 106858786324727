import React from 'react'

const ContactSection = () => {
    return (
        <div className="contact-card-section ptb-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <div className="contact-card">
                                    <i className='bx bx-phone-call'></i>
                                    <ul>
                                        <li>
                                            <a >
                                                +1-456-644-7457
                                            </a>
                                        </li>
                                        <li>
                                            <a >
                                                +1-745-967-4567
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="contact-card">
                                    <i className='bx bx-mail-send' ></i>
                                    <ul>
                                        <li>
                                            <a >
                                                info@jovie.com
                                            </a>
                                        </li>
                                        <li>
                                            <a >
                                                hello@jovie.com
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
                                <div className="contact-card">
                                    <i className='bx bx-location-plus' ></i>
                                    <ul>
                                        <li>
                                            123, Denver, USA
                                        </li>
                                        <li>
                                            Street view 3/B, USA
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactSection