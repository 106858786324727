import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import JobOpenings from "../jobOpenings/JobOpenings";

const Subject = () => {
  const { id, subject } = useParams();
  const filters = [id];
  return (
    <>
      <JobOpenings title={subject} filters={filters}/>
    </>
  );
};

export default Subject;
