import React, { useState, useEffect, useRef } from 'react'
import './Header.css'
import { Link, useHistory, useLocation } from 'react-router-dom'
import logoPlaceholder from '../../../assets/logo.svg'
import language from '../../../assets/language.png'
import language2 from '../../../assets/language2.png'
import userPH from '../../../assets/userPH.png'
import SideMenu from './sideMenu/SideMenu'
import RenderOrNot from '../renderOrNot/RenderOrNot'
import { useTranslation } from "react-i18next";
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux'
import { logoutActoin } from '../../../redux/actions/Auth'
import { useDispatch } from 'react-redux'

const Header = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory()
  const location = useLocation()
  const headerRef = useRef(null)
  const jobRef = useRef(null)
  const teacherRef = useRef(null)
  const schoolRef = useRef(null)
  const blogRef = useRef(null)
  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state?.userDetails?.authData);
  const loading = useSelector((state) => state?.userDetails?.loading);
  const [scrolled, setScrolled] = useState(0);
  const display = () => {
    if (window.scrollY >=65) {
      setScrolled(true)  
    } else {
        setScrolled(false)  
    }
}
window.addEventListener('scroll', display)
  useEffect(() => {
    window.scrollTo(10, 0);
  }, [location]);
  const [width, setWidth] = useState(window.innerWidth);
  const logOut = () => {
    dispatch(logoutActoin())
}
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const Inner = (str) => {
    if (str === "eng") {
      localStorage.setItem("language", JSON.stringify({ lang: "en" }));
      window.location.reload();
    } else {
      localStorage.setItem("language", JSON.stringify({ lang: "arab" }));
      window.location.reload();
    }
  };
  return (
    <header ref={headerRef}>
      <RenderOrNot condition={width <= 900}>
        <SideMenu logOut={logOut} />
        <div className='logo_wraper'>
          <img onClick={() => history.push('/')} src={logoPlaceholder} alt='logo' />
        </div>
      </RenderOrNot>

      <RenderOrNot condition={width > 900}>
        
        <ul>
          <li><button style={{ color: '#008C77' }} ref={jobRef} className='uk-button uk-button-text' onClick={() => history.push('/jobs')}>{t('Job Openings')}</button></li>
          <li><button style={{ color: '#008C77' }} ref={teacherRef} className='uk-button uk-button-text' onClick={() => history.push('/subjects')}>{t("Subjects")}</button></li>
          <li><button style={{ color: '#008C77' }} ref={schoolRef} className='uk-button uk-button-text' onClick={() => history.push('/schools')}>{t("Schools")}</button></li>
          <li><button style={{ color: '#008C77' }} ref={blogRef} className='uk-button uk-button-text' onClick={() => history.push('/blogs')}>{t("BLOG")}</button></li>
        </ul>
        <div className='logo_wraper'>
          <img onClick={() => history.push('/')} src={logoPlaceholder} alt='logo' />
        </div>
        <div className='language_switch'>
            {!localStorage.getItem("sess")?
            <div className='auth_buttons_wrapper'>
              <div onClick={() => Inner(lng === 'arab'? 'eng':'arab')} style={{right:scrolled?'0px':null}} className='language_img'>
              <img  src={lng === 'arab'? language2 : language} alt='langugae'/>
              </div>
              <Link to={{pathname: "/authentication", state: { userView: 1 }}} className='login_btn'>{t("Login")}</Link>
              <Link to={{pathname: "/authentication", state: { userView: 1 }}} className='reg_btn'>{t("Register")}</Link>
            </div>:
            null
          //  <Dropdown>
          //   <Dropdown.Toggle className='user_icon_wrapper' id="dropdown-basic">
          //   <img src={userDetails?.avatar || userPH} alt='user_icon' />
          //   <label className='uk-button uk-button-text'>{t("Account")}</label>
          //   </Dropdown.Toggle>
          //   <Dropdown.Menu>
          //   <Dropdown.Item onClick={() => history.push('/authentication')}>{t("Dashboard")}</Dropdown.Item>
          //   <Dropdown.Item onClick={() => logOut()}>{t("Logout")}</Dropdown.Item>
          //   </Dropdown.Menu>
          //   </Dropdown>
          }
        </div>
      </RenderOrNot>
    </header>
  )
}

export default Header