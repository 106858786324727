import React, { useState, useEffect } from 'react'
import './JobsList.css'
import JobItem from '../job_listItem/Job_Item'
import JobFilterModal from '../jobs_filter/job_filter_modal/JobFilterModal'
import filterIcon from '../../../assets/filter.png'
import { useTranslation } from 'react-i18next'
import NoDataFound from '../../ui/noDataFound/NoDataFound'
import Spinner from '../../ui/spinner/Spinner'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getJobs } from '../../../redux/actions/jobs'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
const JobsList = ({ filters, inputSearch, experience, postDate, salaryRange, teacherCategory }) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const dispatch = useDispatch();
    const [sortState, setSortState] = useState('')
    const [filterModal, setFilterModal] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);
    const { job, loading } = useSelector(state => state.job)
    const test = useSelector(state => state.job)
    const search = useLocation().search;
	const pg = new URLSearchParams(search).get('pg');
	const [pageNumber, setPageNumber] = useState(pg || 1)
	const currentPage = useSelector((state) => state?.job?.currentPage);
	const nextPage = useSelector((state) => state?.job?.nextPage);
	const lastPage = useSelector((state) => state?.job?.lastPage);
    const [numbersOfPages, setNumbersOfPages] = useState([])
    useEffect(() => {
        dispatch(getJobs(pageNumber, lng === 'arab'? 1:2, (filters || ""), (inputSearch || ""), (experience || ""), (postDate || ""), salaryRange, teacherCategory ))
    }, [pageNumber, inputSearch, experience, postDate, salaryRange, teacherCategory])
    useEffect(() => {
		setPageNumber(pg || 1)
	}, [pg])
    useEffect(() => {
    let array = []
    let pages = lastPage
    while (pages > 0 ) {
        array.push(pages)
        pages--
    }
    setNumbersOfPages(array.reverse())
    }, [lastPage])
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    return (
        <>
        {
        loading? 
        <Spinner/>:
        !loading && job?.length === 0 ? 
        <NoDataFound />:
        <div className='jobs_list blog-style-two'>
            <div className='job_list_header'>
                <div className='mobile_filter_header'>
                    {width <= 670 ?
                        <button onClick={() => setFilterModal(true)}>
                            <img src={filterIcon} alt='filter_icon' />
                            {t("Filter")}
                        </button> : null
                    }

                    <label style={{ width: '100%', textAlign: 'left', color: '#6d6b6b', fontSize: '12px' }}>{t('Showing 1 - 10 of 34 results')}</label>
                </div>
                <div className="sort_wrapper">
                    <label style={{ color: '#6d6b6b', fontSize: '12px', width: '50px' }} htmlFor='job_list_select'>{t('Sort by')}:</label>
                    <select className='form-control' style={{ width: 'auto', backgroundImage: 'unset', fontSize:'13px' }} >
                        <option defaultValue=''>{t('Most Recent')}</option>
                        <option>{t('Name')}</option>
                        <option>{t('Salary')}</option>
                    </select>
                </div>
            </div>
            { 
            job?.map((item, index) => (
                <JobItem colorOfText='red' data={item} key={index} />
            ))}
            <JobFilterModal
                show={filterModal}
                dialogClassName="modal-60w"
                onHide={() => setFilterModal(false)}
            />
            <nav aria-label="Page navigation example">
                <ul style={{ padding: '0px' }} className="pagination justify-content-center">
                    <li className={pageNumber === 1? "page-item disabled":"page-item disabled"}>
                        <Link to={`/jobs?pg=${currentPage - 1}`} className="page-link" tabIndex="-1" aria-disabled="true">
                            <i className={lng === 'arab' ? 'bx bx-chevrons-right bx-fade-right' : 'bx bx-chevrons-left bx-fade-left'}></i>
                        </Link>
                    </li>
                    {numbersOfPages.map((number, i) => (
                      <li key={i} className="page-item"><Link
                        to="#"
                        className={pageNumber === number? "page-link active":"page-link"}
                        onClick={() => pageNumber === number? null:setPageNumber(number)}
                      >
                        {number}
                      </Link></li>
                    ))}
                    {/* <li className="page-item"><a className="page-link" >{t('1')}</a></li>
                    <li className="page-item"><a className="page-link active" >{t('2')}</a></li>
                    <li className="page-item"><a className="page-link" >{t('3')}</a></li> */}
                    <li className={nextPage === 1? "page-item disabled":"page-item "}>
                        <Link to={`/jobs?pg=${nextPage}`} className="page-link" >
                            <i className={lng === 'arab' ? 'bx bx-chevrons-left bx-fade-left' : 'bx bx-chevrons-right bx-fade-right'}></i>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>}
        </>
    )
}

export default JobsList