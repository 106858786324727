import React, { useEffect } from "react";
import "./AcademyProfile.css";
import profile from "../../assets/profile1.jpg";
import pic1 from "../../assets/pic1.jpg";
import { useDispatch, useSelector } from "react-redux";
import JobItem from "../jobOpenings/job_listItem/Job_Item";
import { useParams } from 'react-router-dom'
import { getAcademyDetails } from "../../redux/actions/academy_details";
import Spinner from "../ui/spinner/Spinner";
import NoDataFound from "../ui/noDataFound/NoDataFound";
import { useHistory } from 'react-router-dom';

const AcademyProfile = () => {
  const { id } = useParams();
  const history = useHistory()
  const dispatch = useDispatch();
  const { academy, loading } = useSelector((state) => state.academyDetails);
  useEffect(() => {
    dispatch(getAcademyDetails(id, history))
  }, [])
  return (
    <>
    {loading ?
      <Spinner/>
      :
      <div className="academy-profile">
        <div className="row no-gutters">
          <div className="col-xl-12 profile-col">
            <div className="profile-back">
              <img src={academy?.banner || profile} alt="" />
            </div>
            <div className="profile-pic d-flex">
              <img src={academy?.avatar || pic1} alt="" />
              <div className="profile-info2">
                <h2 className="mb-0">{academy?.name || "name"}</h2>
                <h4>{academy?.specialization || "specialization"}</h4>
                <span className="d-block">
                  <i className="fas fa-map-marker-alt me-2"></i>
                  {academy?.location?.country + ", " + academy?.location?.state + "- " + academy?.location?.city || "location"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="all_jibs_wrapper">
          <div className="jobs_list blog-style-two">
            {academy?.jobs?.length === 0 ?
              <NoDataFound/>:
              academy?.jobs?.map((item, index) => (
                <JobItem colorOfText="red" data={item} key={index} name={academy?.name || "name"} avatar={academy?.avatar || pic1} />
              ))
            }
          </div>
        </div>
      </div>
    }
    </>
  );
};

export default AcademyProfile;
