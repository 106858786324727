import React, { useEffect } from 'react'
import './Job_Item.css'
import logoDefault from '../../../assets/logo.png'
import locationIcon from '../../../assets/locationIcon.png'
import { useHistory } from 'react-router'
import { t } from 'i18next'
import { Link } from 'react-router-dom'

const JobItem = (props) => {
    console.log('props', props)
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const history = useHistory()
    return (
        <div style={{ borderTop: props.noBorderTop ? 'none' : null }} className='job_list_item'>
            <div className='img_content_wrapper'>
                {/* TODO:
                change logoDefault || props.data.academy.avatar to props.data.academy.avatar || logoDefault */}
                <img src={ props?.data?.academy?.avatar || props?.avatar || logoDefault} alt='job_title' className='academy_img_as_item' />
                <div style={{ textAlign: lng === 'arab' ? 'right' : 'left' }} className='job_item_content_wrapper'>
                    <Link to={props.jobDetailsPage? '#':`/job-details/${props?.data?.id}`}>
                        {props.data?.title}
                    </Link>
                    <div className='job_content_details'>
                        <Link to={`/job-details?id=${props?.data?.academy?.id || props?.data?.id}`} className='academy_name'>{props.data?.academy?.name || props?.name}</Link>
                        <div className='location'>
                            <img src={locationIcon} className='locationIcon' alt='locationIcon' />
                            <label className='job_locations'>{props.data?.start_date?.split('-')?.shift()} {props.data?.location?.country + t(', ') + props.data?.location?.city}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='job_item_salary'>
                <label className='salary'>
                    {
                    props.data?.publish_salary_information === '1'?
                    props.data?.minimum_salary && props.data?.maximum_salary?
                    t('SAR') + props.data?.minimum_salary + ' - ' + props.data?.maximum_salary:
                    !props.data?.maximum_salary && props.data?.minimum_salary?
                    t('SAR') + props.data?.maximum_salary:
                    t('SAR') + props.data?.minimum_salary:
                    null
                    }
                </label>
                {props.jobDetailsPage?
                null:
                <Link to={`/job-details/${props?.data?.id}`} className='job_statue'>{'View Job'}</Link>
                }
            </div>
        </div>
    )
}

export default JobItem