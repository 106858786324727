import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { getDegreeLevels } from "../../../../../redux/actions/registeration";
import { toastAlert } from "../../../../../layouts/nav/toastAlert";
import { Link } from "react-router-dom";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "react-phone-number-input/style.css";
import {CountryDropdown} from "react-country-region-selector";
import Select from "react-select";


const StepsThree = ({setLoadingModalShow, teacherEducation, submitEducation, addMoreEducationFeilds, removeEducationFeild, educationChange}) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const degrees = useSelector((state) => state?.degrees?.degrees);
  const degreesLoading = useSelector((state) => state?.degrees?.loading);
  useEffect(() => {
    setLoadingModalShow(
      degreesLoading
    )
  }, [degreesLoading])
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getDegreeLevels(lng === 'arab'? 1:2))
  }, [])

  return (
    <>
    <div className="green-bg" />
    <form className="login_form" onSubmit={submitEducation}>
      <div className="regular_login">
        {teacherEducation.map((item, i) => 
      <div key={i} style={i === 0? {width:'100%', marginRight:'0px'}:{borderTop: '1px solid lightgray', width:'100%', marginRight:'0px', paddingTop: '10px', borderTopRightRadius:'20px', borderTopLeftRadius:'20px', position:'relative' }} className="row">
        {i === 0? null:
        <div style={{position: "absolute", top: "0px", left: lng === "arab" ? "0px" : "auto", right: lng === "arab" ? "auto" : "0px", width:'auto'}}>
          <button 
            onClick={() => removeEducationFeild(i)}
            style={{display: "flex", alignItems: "center", justifyContent: "center", border: "none", backgroundColor: "transparent"}}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="#104b08" id="Layer_1" dataName="Layer 1" viewBox="0 0 24 24" width="15" height="15"><path d="M13.93,12L21.666,2.443c.521-.644,.422-1.588-.223-2.109-.645-.522-1.588-.421-2.109,.223l-7.334,9.06L4.666,.557c-1.241-1.519-3.56,.357-2.332,1.887l7.736,9.557L2.334,21.557c-.521,.644-.422,1.588,.223,2.109,.64,.519,1.586,.424,2.109-.223l7.334-9.06,7.334,9.06c.524,.647,1.47,.742,2.109,.223,.645-.521,.744-1.466,.223-2.109l-7.736-9.557Z"/></svg>
          </button>
        </div>}

      <div className="widthMedia form-group mb-4 col-md-6 col-sm-12 col-lg-6">
          <label className="text-label">
            {t("Degree level")}*
          </label>
          <select
            value={item.degree_level_id} name='degree_level_id' onChange={(e) => educationChange(e, i)}
            className="form_control selectInput"
          >
            {degrees?.map((item,i) => 
              <option key={i} value={item?.id}>{item?.name}</option>
            )}
          </select>
        </div>
      <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
        <label className="text-label">
          {t("Field of study")}*
        </label>
        <input
          type="text"
          className="form-control"
          placeholder={t("Math")}
          value={item.title} name='title' onChange={(e) => educationChange(e, i)}
          required
        />
      </div>
      <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
        <label className="text-label">
          {t("Place of Issurance")}*
        </label>
        <input
          type="text"
          className="form-control"
          placeholder={t("University Name")}
          value={item.college} name='college' onChange={(e) => educationChange(e, i)}
          required
        />
      </div>
      {/* <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
        <label className="text-label">
          {t("Valid from")}*
          </label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              label=""
              clearable
              className="form-control"
              name="start_date"
              format="dd/MM/yyyy"
              value={item.start_date}
              onChange={(date)=> educationChange({target: {value:date, name:'start_date'}}, i)}
            />
          </MuiPickersUtilsProvider>
        </div> */}

        <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
        <label className="text-label">
          {t("Year of graduation")}*
          </label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              label=""
              clearable
              className="form-control"
              name="year_of_graduation"
              // format="dd/MM/yyyy"
              views={["year"]}
              // maxDate={`${formatDate(new Date().setFullYear(new Date().getFullYear()-18))}`}
              disableFuture
              value={item.year_of_graduation}
              onChange={(date)=> educationChange({target: {value:date, name:'year_of_graduation'}}, i)}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="widthMedia form-group mb-3 mt-4 col-md-6 col-sm-12 col-lg-6 pb-5">
        <button
                    style={{
                      // fontSize: "15px",
                      // color: "gray",
                      // fontWeight: "bold",
                      textDecorationLine: "underline",
                      color: "#104b08",
                    }}
                    to="#"
                    onClick={() => addMoreEducationFeilds()}
                  >
                     <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus-square-fill"
                      viewBox="0 0 16 16"
                      color="#104b08"
                      onClick={() => addMoreEducationFeilds()}
                    >
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                    </svg>
                   {t("Add another education degree")}
                  </button>
        </div>
        </div>)}
        <button
          id="loginSubmit"
          type="submit"
          className="widthMedia btn mb-2 col-md-6 col-sm-6 col-lg-6 "
          style={{
            backgroundColor: "#104b08",
            color: "white",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          {t("Submit")}
        </button>
          {/* <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', padding: '20px 20px 0px 20px' }} className="widthMedia form-group col-md-12 col-sm-12 col-lg-12">
            <button style={{ fontSize: '11px', color: 'gray' }} to="#" onClick={() => addMoreEducationFeilds()}>{t('Add another education degree')}</button>
          </div> */}
        </div>
    </form>
    </>
  );
};

export default StepsThree;
