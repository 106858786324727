import { FETCH_SCHOOL, SCHOOL_LOADED, LOAD_SCHOOL } from '../constants/actionTypes'
import * as api from '../../api'

export const getSchool = (lang) => async (dispatch) => {
    dispatch({ type: LOAD_SCHOOL });
    try {
        const { data } = await api.getSchools(lang);
        dispatch({ type: FETCH_SCHOOL, payload: data })
    } catch (error) {
        dispatch({ type: SCHOOL_LOADED })
    }
}