import React from 'react'
import ReusableBanner from '../ui/reusableBanner/ReusableBanner'
import Content from './content/Content'
import { useTranslation } from 'react-i18next'

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }}>
      <ReusableBanner title={t("Privacy And Policy")} />
      <Content />
    </div>
  )
}

export default PrivacyPolicy