
import { toastAlert } from '../../../layouts/nav/toastAlert';
import $ from "jquery";

export const logOutFromFB = () => {
    $( window ).on( "load", function() {
        $(document).bind('FBSDKLoaded', function () {
            window.FB.getLoginStatus(function (response) {
                try {
                    if (response.status === "connected") {
                        window.FB.api("/me/permissions", "delete", null);
                        window.FB.logout(function (response) {
                            console.log('hey')
                            // window.FB.Auth.setAuthResponse(null, 'unknown');
                        });
                        // window.FB.login(function (response) {
                        //     // Original FB.login code
                        // }, { auth_type: 'reauthenticate' })
                    }
                    else {
                        // sessionStorage.removeItem(`fbssls_${facebookAppId}`);
                    }
                } catch (error) {
                    toastAlert("Something went wrong...!", undefined, "error");
                }
            }
            )
        });
    })
};