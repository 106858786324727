import React from 'react'
import './Pricing.css'
import ReusableBanner from '../ui/reusableBanner/ReusableBanner'
import { useTranslation } from 'react-i18next'

const Pricing = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;

    return (
        <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }} className='pricing_page'>
            <ReusableBanner background={false} title={t('Pricing')} />
            <section className="pricing-section pb-70">
                <div className="container pricingContainer">
                    <div className="section-title text-center">
                        <h2>{t('Buy Our Plans & Packages')}</h2>
                        <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus")}</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="price-card">
                                <div className="price-top">
                                    <h3>{t('Free Forever')}</h3>
                                    <i className='bx bx-user'></i>
                                    <h2>{t('0')}<sub>/{t("Month")}</sub></h2>
                                </div>

                                <div style={{ textAlign: lng === 'arab' ? 'right' : 'left' }} className="price-feature">
                                    <ul>
                                        <li className="d-flex ">
                                            <i className='bx bx-check'></i>
                                            {t('Appear in results')}
                                        </li>
                                        <li className="d-flex ">
                                            <i className='bx bx-check'></i>
                                            <strong>{t('Accept mobile app')}</strong>
                                        </li>
                                        <li className="d-flex ">
                                            <i className='bx bx-check'></i>
                                            {t('Manage canditates directly')}
                                        </li>
                                    </ul>
                                </div>

                                <div className="price-btn">
                                    <a >{t("Find A Job")}</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="price-card mt-12">
                                <div className="price-top">
                                    <h3>{t('Sponsor')}</h3>
                                    <i className='bx bx-user'></i>
                                    <h2>{t("10")}<sub>/{t("Month")}</sub></h2>
                                </div>

                                <div style={{ textAlign: lng === 'arab' ? 'right' : 'left' }} className="price-feature">
                                    <ul>
                                        <li className="d-flex ">
                                            <i className='bx bx-check'></i>
                                            {t('Premium placement')}
                                        </li>
                                        <li className="d-flex ">
                                            <i className='bx bx-check'></i>
                                            <strong>{t('PPC on your job')}</strong>
                                        </li>
                                        <li className="d-flex ">
                                            <i className='bx bx-check'></i>
                                            {t('Reach more candidates')}
                                        </li>
                                        <li className="d-flex ">
                                            <i className='bx bx-check'></i>
                                            {t('Desktop, mobile job alerts')}
                                        </li>
                                    </ul>
                                </div>

                                <div className="price-btn">
                                    <a >{t('Find A Job')}</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div className="price-card">
                                <div className="price-top">
                                    <h3>{t('Premium Plan')}</h3>
                                    <i className='bx bx-user'></i>
                                    <h2>{t("30")}<sub>/{t("Month")}</sub></h2>
                                </div>

                                <div style={{ textAlign: lng === 'arab' ? 'right' : 'left' }} className="price-feature">
                                    <ul>
                                        <li className="d-flex ">
                                            <i className='bx bx-check'></i>
                                            {t('Job ad live for six-weeks')}
                                        </li>
                                        <li className="d-flex ">
                                            <i className='bx bx-check'></i>
                                            <strong>{t('Premium placement')}</strong>
                                        </li>
                                        <li className="d-flex ">
                                            <i className='bx bx-check'></i>
                                            {t('Desktop, mobile job alerts')}
                                        </li>
                                    </ul>
                                </div>

                                <div className="price-btn">
                                    <a >{t('Find A Job')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Pricing