import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import { t } from "i18next";
import loginImg from "../../../../assets/authBC.png";
import AcademyStep from "./academySteps/AcademyStep";
import { Link } from "react-router-dom";
import { createAcademy } from "../../../../redux/actions/registeration";
import { toastAlert } from "../../../../layouts/nav/toastAlert";
const AcademyReg = ({setLoadingModalShow, setViewOuter}) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [academyFilesInfo, setAcademyFilesInfo] = useState([])
  const [academyFiles, setAcademyFiles] = useState([]);
  const [profileState, setProfileState] = useState('')
  const [profileFile, setProfileFile] = useState('')
  const [types, setTypes] = useState([]);

  const dispatch = useDispatch();    
  const [acadeyRegData, setAcademyRegData] = useState({
    name: '',
    website: '',
    // academy_type_id: '1',
    academy_type_id: [],
    street: "",
    contact_number: '',
    country: 'Saudi Arabia',
    state: '',
    school_type: 'Private',
    academy_files: [],
    bio: '',
    avatar: '',
    international_accreditation_id: '1',
    international_accrediting_bodies: '',
    is_recognised: '1',
    specialization: '',
    types: [],
    city: '',
  })

  const submitFirstStep = (e) => {
    e.preventDefault();
    setLoadingModalShow(true);
    let errorArr = [];
    console.log("acadeyRegData", acadeyRegData)
    let userData = {
      ...acadeyRegData,
      avatar: profileState,
      academy_files: academyFiles,
      types: types
    };
    console.log("userData", userData)
      if (!userData.name) {
        errorArr.push(t("Academy Name is required"))
      }
      if (!userData.city) {
        errorArr.push(t("City is required"))
      }
      if (!userData.specialization) {
        errorArr.push(t("Specialize in is required"))
      }
      if (userData.types.length === 0) {
        errorArr.push(t("Type is required"))
      }
      if (!userData.contact_number) {
        errorArr.push(t('Contact Number is required'))
      }
      // if (!userData.country) {
      //   errorArr.push(t("Country is required"))
      // }
      if (!userData.state) {
        errorArr.push(t("State is required"))
      }
      if (!userData.bio) {
        errorArr.push(t("Academy description is required"))
      }
      if (!userData.street) {
        errorArr.push(t("Address is required"))
      }
      if (!userData.avatar) {
        errorArr.push(t("Avatar is required"))
      }
      if (!userData.school_type) {
        errorArr.push(t("Organization type is required"))
      }
      if (userData.academy_files.length === 0) {
        errorArr.push(t("Please upload your academy files"))
      }
      if (!userData.international_accreditation_id) {
        errorArr.push(t("International Accreditation status required"))
      }
      if (!userData.international_accrediting_bodies && userData.international_accreditation_id == '1') {
        errorArr.push(t("International Accreditation bodies required"))
      }
      if (errorArr.length > 0) {
        toastAlert(errorArr, undefined, "warning")
        setLoadingModalShow(false);
        return false;
      }
    dispatch(createAcademy(userData, setLoadingModalShow));
  }
  return (
    <>
      <div className="teacher_reg">
        <div className="login_page">
          <img src={loginImg} alt="login_img" className="login_img" />
          <div className="login_card_wrapper" style={{ direction: lng === "arab" ? "rtl" : "ltr" }}>
              <h3 className="page_title">{t("Academy Registration")}</h3>
            <div className="cardd overflow-hidden">
              <div style={{ padding: "20px" }} className="card-body innerCardBody_teacher">
                <AcademyStep 
                  submitFirstStep={submitFirstStep} 
                  academyFiles={academyFiles} 
                  setAcademyFiles={setAcademyFiles} 
                  setViewOuter={setViewOuter} 
                  academyFilesInfo={academyFilesInfo}
                  setAcademyFilesInfo={setAcademyFilesInfo}
                  acadeyRegData={acadeyRegData}
                  setAcademyRegData={setAcademyRegData}
                  profileState={profileState}
                  setProfileState={setProfileState}
                  profileFile={profileFile}
                  setProfileFile={setProfileFile}
                  types={types}
                  setTypes={setTypes}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcademyReg;
