import React from 'react'
import { useHistory } from 'react-router'
import logoPlaceholder from '../../../assets/whiteLogo.svg'
import { useTranslation } from 'react-i18next'
import './footer.css'

const Footer = () => {
	const history = useHistory()
	const { t } = useTranslation();
	let lng = JSON.parse(localStorage.getItem("language"))?.lang;
	return (
		<footer style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }}>
			<div style={{ textAlign: lng === 'arab' ? 'right' : 'left' }} className="upper_footer_icon_area pb-70">
				<div className="new_footer_widget">
					<div className="footer-logo">
						<a >
						    <img src={logoPlaceholder} className='new_footer_logo' alt="logo" />
					    </a>
					</div>
					<div className="footer_icons_wrapper">
						<a target="_blank"><i className='bx bxl-facebook'></i></a>
						<a target="_blank"><i className='bx bxl-twitter'></i></a>
						<a target="_blank"><i className='bx bxl-pinterest-alt'></i></a>
						<a target="_blank"><i className='bx bxl-linkedin'></i></a>
					</div>
				</div>
				<div className="new_footer_widget">
					<label>{t("Job Types")}</label>
					<ul style={{ padding: lng === 'arab' ? '0px' : null }}>
						<li><a className='uk-button uk-button-text'>{t("Early Childhood")}</a></li>
						<li><a className='uk-button uk-button-text'>{t("Elementary School")}</a></li>
						<li><a className='uk-button uk-button-text'>{t("Middle School")}</a></li>
						<li><a className='uk-button uk-button-text'>{t("High School")}</a></li>
					</ul>
				</div>
				<div className="new_footer_widget">
					<label>{t("Subjects")}</label>
					<ul style={{ padding: lng === 'arab' ? '0px' : null }}>
						<li><a className='uk-button uk-button-text'>{t("Math")}</a></li>
						<li><a className='uk-button uk-button-text'>{t("Science")}</a></li>
						<li><a className='uk-button uk-button-text'>{t("Arabic")}</a></li>
						<li><a className='uk-button uk-button-text'>{t("English")}</a></li>
						<li><a className='uk-button uk-button-text'>{t("Biology")}</a></li>
					</ul>
				</div>
				<div className="new_footer_widget">
					<label>{t("Schools by city")}</label>
					<ul style={{ padding: lng === 'arab' ? '0px' : null }}>
					    <li><a className='uk-button uk-button-text'>{t("Riyadh")}</a></li>
						<li><a className='uk-button uk-button-text'>{t("Jeddah")}</a></li>
						<li><a className='uk-button uk-button-text'>{t("Al Khobar")}</a></li>
					</ul>
				</div>
				<div className="new_footer_widget">
					<label>{t("Company")}</label>
					<ul style={{ padding: lng === 'arab' ? '0px' : null }}>
						<li><a className='uk-button uk-button-text'>{t("About")}</a></li>
						<li><a className='uk-button uk-button-text'>{t("Contact us")}</a></li>
					</ul>
				</div>
			</div>
			<div className='copyWrites'>

			</div>
		</footer>
	)
}

export default Footer