import React, { useCallback, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { signUpregular, signInWithSocial } from '../../../../../redux/actions/Auth'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'
import { LoginSocialGoogle, LoginSocialFacebook } from 'reactjs-social-login'
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { toastAlert } from '../../../../../layouts/nav/toastAlert'

const SlideOne = (props) => {
    const dispatch = useDispatch()
    const [signUpData, setSignUpData] = useState({
        email: "",
        password: "",
        confirmPassword: ""
    })
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const [codeLinkedIn, setCodeLinkedIn] = useState('')
    const googleRef = useRef(null);
    const facebookRef = useRef(null);
    const handleSignUp = (event) => {
        event.preventDefault();
        if (signUpData.password === signUpData.confirmPassword) {
            props.setLoadingModalShow(true)
            dispatch(signUpregular(signUpData.email, signUpData.password, props.setLoadingModalShow, props.setView))
        } else {
            toastAlert(t("The two passwords don't match"), undefined, "warning");
        }
    }
    const loginWithSocialInner = (loginType, token) => {
        props.setLoadingModalShow(true)
        dispatch(signInWithSocial(loginType, token, props.setLoadingModalShow))
    }
    const onLoginStart = useCallback(() => {
        props.setLoadingModalShow(true)
    }, [])

    // const onLogoutFailure = useCallback(() => {
    //     alert('logout fail')
    // }, [])

    // const onLogoutSuccess = useCallback(() => {
    //     setProfile(null)
    //     setProvider('')
    //     alert('logout success')
    // }, [])
    const { linkedInLogin } = useLinkedIn({
        clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
        onSuccess: (code) => {
            if (code) {
                setCodeLinkedIn(code)
                loginWithSocialInner('linkedin', code)
            }
        },
        onError: (error) => {
            if (!codeLinkedIn) {
                toastAlert(t('Error While Trying to Login With Linkedin'), undefined, "warning")
            }
        },
      });
    return (
        <>
            <form onSubmit={handleSignUp} className="login_form" style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }}>
                <div className='regular_login'>
                    <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                        <label className="text-label">{t('Email Address')}*</label>
                        <input
                            type="email"
                            name="email"
                            className="form_control"
                            placeholder="example@example.com"
                            required="required"
                            onChange={(e) => setSignUpData({ ...signUpData, email: e.target.value })}
                        />
                    </div>
                    <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                        <label className="text-label">{t('Password')}*</label>
                        <input
                            type="password"
                            name="password"
                            className="form_control"
                            placeholder={t("Password")}
                            required="required"
                            onChange={(e) => setSignUpData({ ...signUpData, password: e.target.value })}
                        />
                    </div>
                    <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                        <label className="text-label">{t('Re-Enter Password')}*</label>
                        <input
                            type="password"
                            name="confirmPassword"
                            className="form_control"
                            placeholder={t("Password")}
                            required="required"
                            onChange={(e) => setSignUpData({ ...signUpData, confirmPassword: e.target.value })}
                        />
                    </div>
                    <button id='loginSubmit' type="submit" className="widthMedia btn mb-2 col-md-6 col-sm-6 col-lg-6"
                      style={{
                        backgroundColor: "#104b08",
                        color: "white",
                        padding: "5px",
                        borderRadius: "5px",
                      }}>
                        {t('Register')}
                    </button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px', width: '80%', padding: '10px' }}>
                    <div style={{ width: '80%', height: '1px', backgroundColor: 'gray' }} />
                    <label style={{ textAlign: 'center', margin: '0px', fontSize: '15px', color: 'gray' }}>{t('or')}</label>
                    <div style={{ width: '80%', height: '1px', backgroundColor: 'gray' }} />
                </div>
                <div className='social_login_btns'>
                    <LoginSocialFacebook
                        ref={facebookRef}
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                        auth_type= 'reauthenticate'

                        // onLogoutFailure={onLogoutFailure}
                        onLoginStart={onLoginStart}
                        // onLogoutSuccess={onLogoutSuccess}
                        onResolve={({ provider, data }) => {
                            loginWithSocialInner('facebook', data.accessToken)
                        }}
                        onReject={(err) => {
                            props.setLoadingModalShow(false)
                            toastAlert(t('Error While Trying to Login With Facebook'), undefined, "warning")
                        }}
                    >
                        <i className="fab fa-facebook-f" style={{ color: '#4267B2' }} />
                    </LoginSocialFacebook>

                    <LoginSocialGoogle
                        ref={googleRef}
                        client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        isSignedIn={true}
                        // onLogoutFailure={onLogoutFailure}
                        onLoginStart={onLoginStart}
                        // onLogoutSuccess={onLogoutSuccess}
                        onResolve={({ provider, data }) => {
                            loginWithSocialInner('google', data.access_token)
                        }}
                        onReject={(err) => {
                            props.setLoadingModalShow(false)
                            toastAlert(t('Error While Trying to Login With Google'), undefined, "warning")
                        }}
                    >
                        <i className="fab fa-google" style={{ background: "-webkit-linear-gradient( right,  #DB4437, #F4B400,#4285F4, #0F9D58)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
                    </LoginSocialGoogle>
                    <i onClick={linkedInLogin} className="fab fa-linkedin" style={{ color: "#0072b1" }} />

                </div>
                <div className='haveAnAccount'>
                    <div className="widthMedia form-group mt-10">
                        <Link style={{ fontSize: '11px', color: 'gray' }} to="#" onClick={() => props.setView(0)}>{t('Have an Account?')}</Link>
                    </div>
                </div>
            </form>
        </>

    )
}

export default SlideOne