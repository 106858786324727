/* eslint-disable */

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NoDataFound from '../../ui/noDataFound/NoDataFound';
import Spinner from '../../ui/spinner/Spinner';

const Accordion = ({ faq, loading }) => {
    console.log("faq", faq)
    if (loading) {
        return (
            <Spinner />
        )
    }
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    useEffect(() => {
        $(".accordion-title").on("click", function (e) {
            var accordionitem = $(this).attr("data-tab");
            $("#" + accordionitem).slideToggle().parent().siblings().find(".accordion-content").slideUp();

            $(this).toggleClass("active-title");
            $("#" + accordionitem).parent().siblings().find(".accordion-title").removeClass("active-title");
        });
    }, []);
    return (
        <section style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }} className="faq-section pt-100 pb-70">
            <div className="container">
                <div className="section-title text-center">
                    <h2>{t("Frequently Asked Questions")}</h2>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="accordions">
                            {
                                faq?.length === 0 ?
                                    <NoDataFound />
                                    :
                                    faq?.map((item, index) => (
                                        <div className="accordion-item" key={index}>
                                            <div className="accordion-title" data-tab={item.id}>
                                                <h2>{item?.ar_title || item?.en_title || t("How To Find A Job?")}<i className={lng === 'arab' ? 'bx bx-chevrons-left down-arrow' : 'bx bx-chevrons-right down-arrow'}></i></h2>
                                            </div>
                                            <div className="accordion-content" id={item.id}>
                                                <p>{item?.ar_body || item?.en_body || t("Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo.")}</p>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Accordion
