import React from 'react'
import { useTranslation } from 'react-i18next'

const Content = () => {
    const { t } = useTranslation();
    return (
        <div className="privacy-section pt-100 pb-100">
            <div className="container">
                <div className="privacy-text">
                    <h2 style={{ color: "#134a0e" }}>{t("Privacy Policy")}</h2>
                    <p>{t("React makes it painless to create interactive UIs. Design simple views for each state in your application, and React will efficiently update and render just the right components when your data changes. Declarative views make your code more predictable and easier to debug.")}</p>
                    <p>{t("Build encapsulated components that manage their own state, then compose them to make complex UIs. Since component logic is written in JavaScript instead of templates, you can easily pass rich data through your app and keep state out of the DOM.")}</p>
                    <p>{t("We don’t make assumptions about the rest of your technology stack, so you can develop new features in React without rewriting existing code. React can also render on the server using Node and power mobile apps using React Native")}</p>
                    <p>{t("React has been designed from the start for gradual adoption, and you can use as little or as much React as you need. Whether you want to get a taste of React, add some interactivity to a simple HTML page, or start a complex React-powered app, the links in this section will help you get started.")}</p>
                    <p>{t("The React documentation assumes some familiarity with programming in the JavaScript language. You don’t have to be an expert, but it’s harder to learn both React and JavaScript at the same time. We recommend going through javascript overview to check your knowledge level. It will take you between 30 minutes and an hour but you will feel more confident learning React.")}</p>
                </div>
            </div>
        </div>
    )
}

export default Content