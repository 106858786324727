import { t } from 'i18next';
import React, {useEffect, useCallback, useState} from 'react';
import { useDropzone } from 'react-dropzone';
// import './index.css';
import { toastAlert } from '../../../../layouts/nav/toastAlert';
const LogoFilePicker = ({ children, setLogoState, LogoState, setProfileFile }) => {
    const [filesInfo, setFilesInfo] = useState()
    useEffect(() => {
        setFilesInfo(LogoState)
    }, [LogoState])
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            console.log(typeof(setProfileFile))
          const reader = new FileReader()
          
          reader.readAsDataURL(file);
          reader.onload = function () {
            setLogoState(file)
            toastAlert(t('File uploaded successfuly'), undefined, "success");
            if (setProfileFile) {
                setProfileFile(reader.result)
            }
          };
          reader.onerror = function (error) {
            toastAlert(t('Error while uploading files'), undefined, "warning");
          };
        })
      }, [LogoState])
    const { getRootProps, getInputProps } = useDropzone({onDrop});
    const files = 
    !filesInfo?
    null:
        <li key={filesInfo?.path}>
            {filesInfo?.path} - {filesInfo?.size} bytes
        </li>
    
    return (
        <>
            <div style={{display: 'flex', alignItems:'center', justifyContent: 'center'}} {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {children}
            </div>
            <aside>
                <ul>{files}</ul>
            </aside>
        </>
    );
};

export default LogoFilePicker;