import React from 'react'
import './NewsCom.css'
import defaultWriter from '../../../../assets/defaultData/writer2.jpg'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

const NewsCom = (props) => {
    const history = useHistory()
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    return (
        <div className="blog-card">
            <div className="blog-img">
                <a onClick={() => history.push(`/blog-details?id=${props?.data?.id}`)}>
                    <img src={props?.data?.image || defaultWriter} className='blog_img' alt="blog" />
                </a>
            </div>
            <div className="blog-text">
                <ul>
                    <li><i className='bx bxs-user'></i>{props?.data?.en_owner_name || props?.data?.ar_owner_name || t('Admin')}</li>
                    <li><i className='bx bx-calendar'></i>{props?.data?.published_date || t('7 Feb, 2021')}</li>
                </ul>
                <h3 style={{ textAlign: lng === 'arab' ? 'right' : 'left' }}>
                    <a onClick={() => history.push(`/blog-details?id=${props?.data?.id}`)}>
                        {props?.data?.title || t('How to Indroduce in Yourself in Job Interview?')}
                    </a>
                </h3>
                <p style={{ textAlign: lng === 'arab' ? 'right' : 'left' }} className='blog_content'>{props?.data?.body || t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus")}</p>
                <a onClick={() => history.push(`/blog-details?id=${props?.data?.id}`)} className="blog-btn">{t("Read More")}<i className='bx bx-plus bx-spin'></i></a>
            </div>
        </div>
    )
}

export default NewsCom