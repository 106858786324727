import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGrades,
  getSubjects,
  getLicenseTypes,
} from "../../../../../redux/actions/registeration";
import { Link } from "react-router-dom";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "react-phone-number-input/style.css";
import { CountryDropdown } from "react-country-region-selector";
import Select from "react-select";

const StepsTwo = ({
  setLoadingModalShow,
  teachingLicense,
  addMoreTeachingLicenseFeilds,
  removeTeachingLicenseFeild,
  teachingLicenseChange,
  submitTeachingLicense,
}) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const licence = useSelector((state) => state?.licence?.licence);
  const licenceLoading = useSelector((state) => state?.licence?.loading);
  const grades = useSelector((state) => state?.grades?.grades);
  const gradesLoading = useSelector((state) => state?.grades?.loading);
  const subjects = useSelector((state) => state?.subjects?.subjects);
  const subjectsLoading = useSelector((state) => state?.subjects?.loading);
  useEffect(() => {
    setLoadingModalShow(licenceLoading || gradesLoading || subjectsLoading);
  }, [licenceLoading, gradesLoading, subjectsLoading]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLicenseTypes(lng === "arab" ? 1 : 2));
    dispatch(getGrades(lng === "arab" ? 1 : 2));
    dispatch(getSubjects(lng === "arab" ? 1 : 2));
  }, []);

  return (
    <>
      <div className="green-bg" />
      <form className="login_form" onSubmit={submitTeachingLicense}>
        <div className="regular_login">
          {teachingLicense.map((item, i) => (
            <div
              key={i}
              style={
                i === 0
                  ? { width: "100%", marginRight: "0px" }
                  : {
                      borderTop: "1px solid lightgray",
                      width: "100%",
                      marginRight: "0px",
                      paddingTop: "10px",
                      borderTopRightRadius: "20px",
                      borderTopLeftRadius: "20px",
                      position: "relative",
                    }
              }
              className="row"
            >
              {i === 0 ? null : (
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: lng === "arab" ? "0px" : "auto",
                    right: lng === "arab" ? "auto" : "0px",
                    width: "auto",
                  }}
                >
                  <button
                    onClick={() => removeTeachingLicenseFeild(i)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#104b08"
                      id="Layer_1"
                      dataName="Layer 1"
                      viewBox="0 0 24 24"
                      width="15"
                      height="15"
                    >
                      <path d="M13.93,12L21.666,2.443c.521-.644,.422-1.588-.223-2.109-.645-.522-1.588-.421-2.109,.223l-7.334,9.06L4.666,.557c-1.241-1.519-3.56,.357-2.332,1.887l7.736,9.557L2.334,21.557c-.521,.644-.422,1.588,.223,2.109,.64,.519,1.586,.424,2.109-.223l7.334-9.06,7.334,9.06c.524,.647,1.47,.742,2.109,.223,.645-.521,.744-1.466,.223-2.109l-7.736-9.557Z" />
                    </svg>
                  </button>
                </div>
              )}
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("Place of Issurance")}*</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Academy Name")}
                  value={item.place_of_issuance}
                  name="place_of_issuance"
                  onChange={(e) => teachingLicenseChange(e, i)}
                  required
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("License Type")}*</label>
                <select
                  value={item.teaching_license_type_id}
                  name="teaching_license_type_id"
                  onChange={(e) => teachingLicenseChange(e, i)}
                  className="form-control"
                >
                  {licence?.map((item, i) => (
                    <option key={i} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                <label className="text-label">
                  {t("Grade(s) you are licensed to teach")}*
                </label>
                <Select
                  value={item.grades}
                  name="grades"
                  onChange={(selectedOption) =>
                    teachingLicenseChange(
                      { target: { value: selectedOption, name: "grades" } },
                      i
                    )
                  }
                  placeholder={t("Select Grade(s)")}
                  options={grades?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                <label className="text-label">
                  {t("Subject(s) you are licensed to teach")}*
                </label>
                <Select
                  value={item.subjects}
                  name="subjects"
                  onChange={(selectedOption) =>
                    teachingLicenseChange(
                      { target: { value: selectedOption, name: "subjects" } },
                      i
                    )
                  }
                  placeholder={t("Select Subject(s)")}
                  options={subjects?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                <label className="text-label">
                  {t("Country your license was issued in")}*
                </label>
                <CountryDropdown
                  className="form-control"
                  defaultOptionLabel={t("Select Country")}
                  value={item.country}
                  name="country"
                  onChange={(val) =>
                    teachingLicenseChange(
                      { target: { value: val, name: "country" } },
                      i
                    )
                  }
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("Valid from")}*</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    label=""
                    clearable
                    className="form-control"
                    name="valid_from"
                    format="dd/MM/yyyy"
                    value={item.valid_from}
                    onChange={(date) =>
                      teachingLicenseChange(
                        { target: { value: date, name: "valid_from" } },
                        i
                      )
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
              {item.has_expiration_date == "1" ? (
                <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                  <label className="text-label">{t("Expiry")}*</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      label=""
                      clearable
                      className="form-control"
                      name="valid_to"
                      format="dd/MM/yyyy"
                      value={item.valid_to}
                      onChange={(date) =>
                        teachingLicenseChange(
                          { target: { value: date, name: "valid_to" } },
                          i
                        )
                      }
                    />
                  </MuiPickersUtilsProvider>
                </div>
              ) : null}

              <div
                // style={{ display: "flex", justifyContent: "space-between" }}
                // className="form-check mt-3 mb-4"
                className="row"
              >
                <div 
                style={{ display: "flex", gap: "7px" }}
                className="col-md-12 col-sm-12 col-lg-6 mb-3"
                >
                  <input
                    style={{
                      float: "unset",
                      marginRight: "unset",
                      marginLeft: "unset",
                    }}
                    checked={item.has_expiration_date == "0"}
                    value={item.has_expiration_date}
                    onChange={() =>
                      teachingLicenseChange(
                        {
                          target: {
                            value: item.has_expiration_date == "0" ? "1" : "0",
                            name: "has_expiration_date",
                          },
                        },
                        i
                      )
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label className="form-check-label">
                    {t("No expiration date")}
                  </label>
                </div>
                <div
                  className="col-md-12 col-sm-12 col-lg-6 pb-5 mb-3"
                  // style={{ display: "flex", justifyContent: "flex-end" }}
                >
                 
                  <button
                    style={{
                      // fontSize: "15px",
                      // color: "gray",
                      // fontWeight: "bold",
                      textDecorationLine: "underline",
                      color: "#104b08",
                    }}
                    to="#"
                    onClick={() => addMoreTeachingLicenseFeilds()}
                  >
                     <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus-square-fill"
                      viewBox="0 0 16 16"
                      color="#104b08"
                      onClick={() => addMoreTeachingLicenseFeilds()}
                    >
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                    </svg>
                   {t(" Add another teaching license")}
                  </button>
                </div>
              </div>
            </div>
          ))}

          <button
            id="loginSubmit"
            type="submit"
            className="widthMedia btn mb-2 col-md-6 col-sm-6 col-lg-6"
            style={{
              backgroundColor: "#104b08",
              color: "white",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            {t("Submit")}
          </button>
        </div>
      </form>
    </>
  );
};

export default StepsTwo;
