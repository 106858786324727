import { combineReducers } from "redux";
import aboutUs from './about_page/about'
import faq from "./faq_page/faq";
// import teacher from "./teacher_page/teacher";
import school from "./school_page/school";
import blog from "./blog_page/blog";
import article from "./article_page/article";
import job from "./job_page/job";
import homeArticle from './home_page/articles';
import homeAvailableJob from './home_page/availableJobs';
import homeSubject from './home_page/subject';
import homeBanner from './home_page/banner';
import homeJob from './home_page/jobLevel';
import userDetails from './user/User'
import jobLevels from './job_level/JobLevel';
import sizes from './job_level/Size';
import yearsOfTeaching from './job_level/YearOfTeaching';

import jobTitles from "./registeration/jobTitles";
import languages from "./registeration/languages";
import degrees from "./registeration/degrees";
import grades from "./registeration/grades";
import citizenships from "./registeration/citizenships";
import subjects from "./registeration/subjects";
import gender from './registeration/gender'
import curriculums from "./registeration/curriculums";
import licence from "./registeration/licence";
import increaseViews from "./increase_views/increaseViews";
import academyDetails from "./academy_details/academyDetails";
import subjectDetails from "./subject_details/subjectDetails"
import maxSalary from "./max_salary/maxSalary"
import popularJobTitles from "./popular_jobs/popularJobTitles"

export default combineReducers({
    aboutUs,
    faq,
    // teacher,
    school,
    blog,
    article,
    job,
    homeArticle,
    homeAvailableJob,
    homeSubject,
    homeBanner,
    homeJob,
    userDetails,
    jobLevels,
    sizes,
    yearsOfTeaching,
    jobTitles,
    languages,
    degrees,
    grades,
    citizenships,
    subjects,
    gender,
    curriculums,
    licence,
    increaseViews,
    academyDetails,
    subjectDetails,
    maxSalary,
    popularJobTitles,
});