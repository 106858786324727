import React from 'react'
import { Link } from 'react-router-dom';
import logoPlaceholder from '../../../assets/logo_wide_white.png'

const AuthHeader = () => {
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const handleLanguage = () => {
        localStorage.setItem("language", JSON.stringify({ lang: lng === 'arab'? "en":'arab' }));
        window.location.reload();
    }
  return (
    <header id='auth_header' style={{position:'absolute', backgroundColor:'transparent', display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%', padding: '20px 30px 20px 30px', zIndex:'100'}}>
        <Link to="/"><img src={logoPlaceholder} alt='back_btn' className='header_back_btn'/></Link>
        <button onClick={handleLanguage} className={lng === 'arab'? 'languageChangeBtn forceEnglishFont':'languageChangeBtn forceArabicFont'}>
            {lng === 'arab'? 'English':"العربية"}
        </button>
    </header>
  )
}

export default AuthHeader