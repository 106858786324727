import React from 'react'
import './JobDetailsSection.css'
import JobItem from '../../jobOpenings/job_listItem/Job_Item'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Spinner from '../../ui/spinner/Spinner'
import parse from 'html-react-parser';

const JobDetailsSection = ({jobDetails}) => {
    const { t } = useTranslation();
    const jobDesc = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fermentum blandit mi, in porta justo lobortis non. Mauris efficitur semper neque. Sed nisi ipsum, elementum elementum placerat ac, aliquet quis purus. Ut et tempor enim. Curabitur sit amet semper tellus. Vivamus vulputate rutrum nibh id interdum. Nulla eu turpis sit amet risus auctor varius.Donec in lobortis ligula, eget posuere mi. Phasellus condimentum lacinia sapien, in lacinia nibh congue suscipit. Etiam nulla turpis, ultricies id augue et, vehicula rhoncus augue. Sed tristique dolor velit, sed iaculis dui iaculis sit amet. Mauris vitae velit odio. Sed aliquet pharetra est a vehicula. Quisque malesuada vitae neque ac tempus. In efficitur leo sed augue ornare, a molestie felis tincidunt. Suspendisse bibendum odio quis felis malesuada, eu viverra nisi interdum."
    // const { job, loading } = useSelector(state => state.job)
    const job = {data: [1]};
    const loading = false
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');
    const index = job?.data?.map(object => object?.id).indexOf(Number(id))

    return (!loading && job?.data) ? (
        <div className='JobDetailsSection'>
            <JobItem jobDetailsPage noBorderTop colorOfText='red' data={jobDetails} />
            <div className='job_details_content'>
                <div className='job_details_content_section'>
                    <h6>{t("Job Description")}</h6>
                    <div style={{width:'100%'}}>{parse(jobDetails?.description)}</div>
                </div>
                {jobDetails?.types_of_educators?.length || (jobDetails?.min_education && jobDetails?.min_education?.id != 3) ||
                jobDetails?.specific_major || jobDetails?.minimum_teaching_experience || jobDetails?.required_certifications?.length || 
                (jobDetails?.citizenship_match_required && jobDetails?.citizenship_match_required != '0')?
                <div className='job_details_content_section'>
                    <h6>{t("Requirements")}</h6>
                    <ul>
                        {jobDetails?.types_of_educators?.length?
                        <li>
                            {t("You must be a ") + jobDetails?.types_of_educators?.map((item, i) => item?.name + (i !== jobDetails?.types_of_educators?.length -1? t('Or'):' ')) + t("to apply for this role")}
                        </li>:null}
                        {jobDetails?.min_education && jobDetails?.min_education?.id != 3?
                        <li>
                            {t("You must have a ") + jobDetails?.min_education?.name + ' ' + t('or higher') + ' ' + t("to apply for this role")}
                        </li>:null}
                        {jobDetails?.specific_major?
                        <li>
                            {t("Specific Major: ") + jobDetails?.specific_major}
                        </li>:null}
                        {jobDetails?.minimum_teaching_experience?
                        <li>
                            {t("You must have a ") + jobDetails?.minimum_teaching_experience + ' ' + t(" teaching years of experience") + ' ' + t("to apply for this role")}
                        </li>:null}
                        {jobDetails?.required_certifications?.length?
                        <li>
                            {t("Certifications Required: ") + jobDetails?.required_certifications?.map((item, i) => item?.name + (i !== jobDetails?.required_certifications?.length -1? t('Or'):''))}
                        </li>:null}
                        {jobDetails?.citizenship_match_required && jobDetails?.citizenship_match_required != '0'?
                        <li>
                            {t("Citizenships Required: ") + jobDetails?.citizenships?.map((item, i) => item?.name + (i !== jobDetails?.citizenships?.length -1? t(', '):''))}
                        </li>:null}
                    </ul>
                </div>:null}
                {jobDetails?.other_requirements?
                <div className='job_details_content_section'>
                    {jobDetails?.types_of_educators?.length || (jobDetails?.min_education && jobDetails?.min_education?.id != 3) ||
                    jobDetails?.specific_major || jobDetails?.minimum_teaching_experience || jobDetails?.required_certifications?.length || 
                    (jobDetails?.citizenship_match_required && jobDetails?.citizenship_match_required != '0')?
                    <h6>{t("Other Requirements")}</h6>:
                    <h6>{t("Requirements")}</h6>}
                    <div style={{width:'100%'}}>{parse(jobDetails?.other_requirements)}</div>
                </div>:null}
                {jobDetails?.airfare || jobDetails?.accommodation?
                <div className='job_details_content_section'>
                    <h6>{t("Benefits")}</h6>
                    <ul>
                        {jobDetails?.airfare?
                        <li>
                            {jobDetails?.airfare}
                        </li>:null}
                        {jobDetails?.accommodation?
                        <li>
                            {jobDetails?.accommodation}
                        </li>:null}
                    </ul>
                </div>:null}
                {jobDetails?.other_benefits?
                <div className='job_details_content_section'>
                    {jobDetails?.airfare || jobDetails?.accommodation?
                    <h6>{t("Other Benefits")}</h6>:
                    <h6>{t("Benefits")}</h6>}
                    <div style={{width:'100%'}}>{parse(jobDetails?.other_benefits)}</div>
                </div>:null}
            </div>
        </div>
    ) : (
        <Spinner />
    )
}

export default JobDetailsSection