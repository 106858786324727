import React, {useEffect, useState} from 'react'
import './JobDetails.css'
import JobDetailsSection from './jobDetalsSection/JobDetailsSection'
import ReusableBanner from '../ui/reusableBanner/ReusableBanner'
import JobRightSection from './jobRightSection/JobRightSection'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getJobDetails } from '../../redux/actions/jobs'
import Spinner from '../ui/spinner/Spinner'
import { useHistory } from 'react-router-dom'
import NoDataFound from '../ui/noDataFound/NoDataFound'
import axios from "axios";

const JobDetails = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory();
  const [jobDetails, setJobDetails] = useState({})
  const [loadingModalShow, setLoadingModalShow] = useState(true)
  const [view, setView] = useState(0)
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("sess")) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("sess")}`;
    }
      dispatch(getJobDetails(id, setLoadingModalShow, setJobDetails, lng === 'arab'? 1:2 ))
  }, [id])
  useEffect(() => {
    if (loadingModalShow) {
      setView(0)
    } else if (!loadingModalShow && Object.keys(jobDetails).length === 0) {
      setView(3)
    } else {
      setView(1)
    }
  }, [jobDetails, loadingModalShow])
  return (
    <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }} className='pages_with_header'>
      <ReusableBanner title={jobDetails?.title} />
      <div style={view === 3 || view === 0? {gridTemplateColumns: '100%', minHeight:'400px'}:{minHeight: '600px'}} className='all_jibs_wrapper all_jibs_wrapper_900'>
        {view === 0?
        <Spinner/>:
        view === 1?
        <>
        <JobDetailsSection jobDetails={jobDetails}  />
        <JobRightSection jobDetails={jobDetails} setJobDetails={setJobDetails} />
        </>:
        <NoDataFound />
      } 
      </div>
    </div>
  )
}

export default JobDetails