export const TRANSLATIONS_ARAB = {
    "Menu": "القائمة",
    "Job Openings": "الوظائف المتاحة",
    "JOB OPENINGS": "الوظائف المتاحة",
    "Teachers": "المدرسين",
    "Schools": "المدارس",
    "Subjects": "المواد الدراسية",
    "FIND THE PERFECT SCHOOL TO TEACH": "احصل على المدرسة المثالية للتدريس",
    "Job Title or Keyword": "المسمى الوظيفي",
    "Location": "الموقع",
    "Category": "الفئة",
    "FIND A JOB": "البحث عن وظيفة",
    "AVAILABLE JOBS": "الوظائف المتاحة",
    "POPULAR SUBJECTS": "المواد الدراسية الرائجة",
    "Filter": "تصفية",
    "JOB DETAILS": "تفاصيل الوظيفة",
    "Apply Now": "التقديم الآن",
    "Job Overview": "نظرة عامة",
    "Save Job": "حفظ الوظيفة",
    "open position": "وظائف شاغرة",
    "JOB TYPES": "فئات التوظيف",
    "BLOG": "مقالات",
    "Blog": "مقالات",
    "Search Company": "البحث عن شركة",
    "Keyword": "الكلمة الرئيسية",
    "Any Category": "أي فئة",
    "Company Sizes": "مقاسات الشركة",
    "All Company Sizes": "جميع مقاسات الشركة",
    "Search": "بحث",
    "Envato": "إنفاتو",
    "2 open positions": "2 وظائف شاغرة",
    "Melbourne, Australia": "ملبورن أستراليا",
    // Privacy Policy
    "Privacy Policy": "سياسة الخصوصية",
    "Privacy And Policy": "سياسة الخصوصية",
    "React makes it painless to create interactive UIs. Design simple views for each state in your application, and React will efficiently update and render just the right components when your data changes. Declarative views make your code more predictable and easier to debug.": "تجعل React إنشاء واجهات مستخدم تفاعلية أمرًا غير مؤلم. صمم طرق عرض بسيطة لكل حالة في تطبيقك ، وستقوم React بتحديث المكونات الصحيحة فقط وعرضها عندما تتغير بياناتك. تجعل العروض التقريرية شفرتك أكثر قابلية للتنبؤ وأسهل في التصحيح.",
    "Build encapsulated components that manage their own state, then compose them to make complex UIs. Since component logic is written in JavaScript instead of templates, you can easily pass rich data through your app and keep state out of the DOM.": "قم ببناء مكونات مغلفة تدير حالتها الخاصة ، ثم قم بتكوينها لإنشاء واجهات مستخدم معقدة. نظرًا لأن منطق المكون مكتوب بلغة JavaScript بدلاً من القوالب ، يمكنك بسهولة تمرير بيانات غنية من خلال تطبيقك وإبقاء الحالة خارج DOM.",
    "We don’t make assumptions about the rest of your technology stack, so you can develop new features in React without rewriting existing code. React can also render on the server using Node and power mobile apps using React Native": "نحن لا نفترض افتراضات بشأن بقية مجموعة التقنيات لديك ، لذا يمكنك تطوير ميزات جديدة في React دون إعادة كتابة التعليمات البرمجية الحالية. يمكن أن يتم عرض React أيضًا على الخادم باستخدام تطبيقات الأجهزة المحمولة Node والطاقة باستخدام React Native",
    "React has been designed from the start for gradual adoption, and you can use as little or as much React as you need. Whether you want to get a taste of React, add some interactivity to a simple HTML page, or start a complex React-powered app, the links in this section will help you get started.": "تم تصميم React من البداية للتبني التدريجي ، ويمكنك استخدام القليل من React أو قدر ما تحتاجه. سواء كنت ترغب في تذوق React ، أو إضافة بعض التفاعل إلى صفحة HTML بسيطة ، أو بدء تطبيق معقد مدعوم من React ، فإن الروابط الموجودة في هذا القسم ستساعدك على البدء.",
    "The React documentation assumes some familiarity with programming in the JavaScript language. You don’t have to be an expert, but it’s harder to learn both React and JavaScript at the same time. We recommend going through javascript overview to check your knowledge level. It will take you between 30 minutes and an hour but you will feel more confident learning React.": "تفترض وثائق React بعض الإلمام بالبرمجة بلغة JavaScript. ليس عليك أن تكون خبيرًا ، ولكن من الصعب تعلم كل من React و JavaScript في نفس الوقت. نوصي بالاطلاع على نظرة عامة على جافا سكريبت للتحقق من مستوى معرفتك. سيستغرق الأمر ما بين 30 دقيقة وساعة ، لكنك ستشعر بثقة أكبر في تعلم React.",
    // Contact us
    "Lets' Talk With Us": "لنتحدث معنا",
    "Your Name": "اسمك",
    "Your Email": "بريدك الالكتروني",
    "Phone Number": "رقم الهاتف",
    "Your Subject": "موضوعك",
    "Write Message": "اكتب رسالة",
    "Send Message": "أرسل رسالة",
    // Faq
    "Frequently Asked Questions": "أسئلة مكررة",
    // "bx bx-chevrons-right down-arrow" : "bx bx-chevrons-left down-arrow",
    // About us 
    "How We Started": "كيف بدأنا",
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.": "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. الهدف من استخدام لوريم إيبسوم هو أنه يحتوي على توزيع طبيعي -إلى حد ما- للأحرف ، بدلاً من استخدام هنا يوجد محتوى نصي ، هنا يوجد محتوى نصي ، مما يجعلها تبدو وكأنها إنجليزية قابلة للقراءة.",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.": "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. لوريم إيبسوم هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي.",
    "Easiest Way To Use": "أسهل طريقة للاستخدام",
    "Browse Job": "تصفح الوظيفة",
    "Find Your Vaccancy": "ابحث عن الوظيفة الشاغرة",
    "Submit Resume": "تقديم السيرة الذاتية",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor": " أبجد هوز دولور الجلوس امات مستشار شحم النخبة سيد  دو إيوسمود",
    "1": "١",
    "2": "٢",
    "3": "٣",
    "Why You Choose Us Among Other Job Site?": "لماذا تختارنا من بين مواقع العمل الأخرى؟",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus": " أبجد هوز دولور الجلوس امات مستشار شحم النخبة سيد  دو إيوسمود هو ببساطة نص وهمي لصناعة الطباعة والتنضيد. كان لوريم إيبسوم الدمية القياسية في هذه الصناعة",
    "Advertise Job": "أعلن عن وظيفة",
    "Recruiter Profiles": "ملامح المجند",
    "Find Your Dream Job": "ابحث عن الوظيفة التي تحلم بها",
    "Grow Your Business Faster With Premium Advertising": "ينمو عملك بشكل أسرع مع الإعلانات المميزة",
    "Checkout More": "تحقق من المزيد",
    "Job Posted": "تم نشر الوظيفة",
    "Job Filed": "مجال العمل",
    "Company": "شركة",
    "Education Information": "المعلومات الدراسية",
    "Skip": "تخطي",
    "Add another education degree": "أضف درجة علمية أخرى",
    "Submit": " تقديم" ,
    "Degree level": "الدرجة العلمية",
    "Field of study": "مجال الدراسة",
    "Place of Issurance": "مكان الإصدار",
    "Valid from": "تاريخ الإصدار",
    "Math": "الرياضيات",
    "University Name": "اسم الجامعة",
    "Experience Information": "معلومات الخبرات السابقة",
    "Go to Dashboard": "الذهاب إلى لوحة التحكم",
    "Position Title": "المسمى الوظيفي",
    "School or District": "مكان العمل",
    'Select Grade(s)': "اختر المستويات",
    "Select Country": "اختر دولة",
    "Computer Teacher": "مدرس حاسب آلي",
    "Academy Name": "اسم الأكاديمية",
    "Teaching License Information": "معلومات رخصة التدريس",
    "License Type": "نوع الرخصة",
    "Grade(s) you are licensed to teach" : "المستويات التعليمية التي رُخص لك تدريسها",
    "Subject(s) you are licensed to teach": "الموضوعات التي رُخص لك تدريسها",
    "Country your license was issued in": "الدولة التي صدرت منها الرخصة",
    "No expiration date": "لا يوجد تاريخ انتهاء",
    "Add another teaching license": "أضف رخصة تدريسية أخرى",
    "Password": "كلمة المرور",
    "Forget Password?": "نسيت كلمة السر؟",
    "Login": "تسجيل الدخول",
    "or": "أو",
    "Don't Have Account?": "ليس لديك حساب؟",
    "LOGIN": "تسجيل الدخول",
    "Email Address": "البريد الإلكتروني",
    "Re-Enter Password": "تأكيد كلمة المرور",
    "Have an Account?": "لديك حساب بالغعل؟",
    "Register": "تسجيل" ,
    "SIGN UP": "تسجيل حساب جديد" ,
    "Reset Password": "إعادة تعيين كلمة المرور",
    "Account Activation": "تفعيل الحساب",
    "Thanks For Registeration, Admins Are Reviewing Your Account Details Right Now": "شكرًا للتسجيل، المدراء يعملون حاليًا على مراجعة حسابك",
    "Logout": "تسجيل الخروج",
    "Who are You?": "عرفنا بنفسك",
    "Teacher": "معلم",
    "Academy": "أكاديمية",
    "General Information": "معلومات عامة",
    "Which job title fits you best?": "أي المسميات الوظيفية تناسبك أكثر؟",
    "Do you have an ESL teaching certificate?": "هل لديك شهادة تدريس ESL؟",
    "Do you have a teaching license or credential?": "هل لديك رخصة تدريس؟",
    "interesting in online teaching?": "هل أنت مهتم بالتعليم عن بعد؟",
    "Next": "المتابعة",
    "Yes": "نعم",
    "No": "لا",
    "Upload your Profile Picture": "أرفق صورتك الشخصية",
    "First Name": "الاسم الأول",
    "Last Name": "اسم العائلة",
    "Date of Birth": "تاريخ الميلاد",
    "Where do you live?": "أين تعيش",
    "City": "المدينة",
    "Citizenship(s)": "الجنسيات التي تحملها",
    "Fluent Languages": "اللغات التي تتقنها",
    "Contact Number": "رقم التواصل",
    "Upload your resume or CV": "أرفق سيرتك الذاتية",
    "I consent to Madares receiving this document and sharing it with employers": "أنا موافق على استلام مدارس تلك الوثيقة ومشاركتها مع الجهات التوظيفية",
    "Allow hiring schools to contact me directly": "السماح للجهات التوظيفية بالتواصل معي مباشرة",
    "Gamal": "جمال",
    "Hazim": "حازم",
    "Select State": "اختر مدينة",
    "Select citizenship(s)": "اختر من بين الجنسيات",
    "Select Language(s)": "اختر من بين اللغات",
    "Enter phone number": "أدخل رقهم هاتفك",
    "Welcome": "مرحبًا",
    ', ': "، ",
    "Academy Registration": "تسجيل أكاديمية",
    "Success Academy": "أكاديمية النجاح",
    "Academy website": "موقع الأكاديمية الإلكتروني",
    "Academy Type": "نوع الأكاديمية",
    "Address": "العنوان",
    "Organization Type": "نوع الهيئة",
    "Academy Description": "وصف الأكاديمية",
    "Details": "التفاصيل",
    "Upload Academy Logo": "أرفق شعار الأكاديمية",
    "International Accreditation": "الاعتماد الدولي",
    "Please upload these files": "من فضلك قم بإرفاق الملفات التالية",
    "Document Nummber 1": "وثيقة رقم ١",
    "Document Nummber 2": "وثيقة رقم ٢",
    "Document Nummber 3": "وثيقة رقم ٣",
    "Please list international accrediting bodies you are accredited by": "يرجى ذكر جهات الاعتماد الدولية التي تم اعتمادك من قبلها",
    "Full URL": "رابط كامل",
    "We are a recognised educational institution by our Federal or State/Provincial Government": "نحن مؤسسة تعليمية معترف بها من قبل الحكومة الفيدرالية أو حكومة الولاية / المقاطعة",
    "Email Verification": "تفعيل الحساب",
    "Please Check Your Email For A Verification Link": "رجاًء مراجعة بريدك الإلكتروني بحثًا عن رابط التفعيل",
    "Re-send Verification Email": "أعد إرسال بريد التفعيل",
    "Forgot Password": "نسيت كلمة السر",
    "Back": "العودة",
    'Select Subject(s)': "اختر الموضوعات",
    "Country": "الدولة",
    "Curriculum you taught": "المنهج الذي درسته",
    "Grade(s) you taught": "المستويات التعليمية التي درستها",
    "Subject(s) you taught": "الموضوعات التي درستها",
    "From": "من",
    "To": "إلى",
    "I am currently working in this role": "مازلت أعمل هناك",
    "Add another experience": "أضف خبرات أخرى",
    "Expiry": "تاريخ الإنتهاء",
    "Members": "أعضاء",
    "1225": "١٢٢٥",
    "145": "١٤٥",
    "170": "١٧٠",
    "125": "١٢٥",
    "What Client’s Say About Us": "ماذا يقول العميل عنا",
    // "News, Tips & Articles": "أخبار ونصائح ومقالات",
    "Admin": "مسؤل",
    "7 Feb, 2021": "٧ فبراير ٢٠٢١",
    "How to Indroduce in Yourself in Job Interview?": "كيف تندمج في نفسك في مقابلة العمل؟",
    "Read More": "اقرأ أكثر",
    "Looking for Highly Motivated Product to Build": "البحث عن منتج شديد الحافز للبناء",
    "The Reason Why Software Developer is the Best Job": "السبب الذي يجعل مطور البرامج هو أفضل وظيفة",
    "left": "right",
    "right": "left",
    //Pricing
    "Buy Our Plans & Packages": "اشتر خططنا وباقاتنا",
    "Free Forever": "حر للابد",
    "Contact Us": "تواصل معنا",
    "Sponsor": "راعي",
    "Premium Plan": "خطة بريميوم",
    "0": "٠",
    "Month": "شهر",
    "10": "١٠",
    "30": "٣٠",
    "Appear in results": "تظهر في النتائج",
    "Accept mobile app": "قبول التطبيق المحمول",
    "Manage canditates directly": "إدارة المرشحين مباشرة",
    "Find A Job": "ابحث عن وظيفة",
    "Premium placement": "التنسيب قسط",
    "PPC on your job": "PPC في عملك",
    "Reach more candidates": "الوصول إلى المزيد من المرشحين",
    "Desktop, mobile job alerts": "سطح المكتب ، تنبيهات الوظائف المتنقلة",
    "Job ad live for six-weeks": "إعلان الوظيفة يعيش لمدة ستة أسابيع",
    // job opening
    "Search Keywords": "كلمات البحث",
    "e.g. web design": "على سبيل المثال تصميم الويب",
    "Any category": "أي فئة",
    "Job Type": "نوع الوظيفة",
    "All type": "كل الأنواع",
    "Full Time": "وقت كامل",
    "Part Time": "دوام جزئى",
    "Freelance": "حسابهم الخاص",
    "Internship": "التدريب الداخلي",
    "Temporary": "مؤقت",
    "Salary Range": "نطاق الراتب",
    "Experince": "خبرة",
    "Any Experince": "أي خبرة",
    "1 Year to 2 Years": "من سنة إلى سنتين",
    "2 Year to 3 Years": "٢ سنة إلى ٣ سنوات",
    "3 Year to 4 Years": "٣ سنوات إلى ٤ سنوات",
    "4 Year to 5 Years": "٤ سنوات إلى ٥ سنوات",
    "Date Posted": "تاريخ الإعلان",
    "Any Date": "أي تاريخ",
    "Last Hour": "الساعة الأخيرة",
    "Last 24 Hours": "أخر ٢٤ ساعه",
    "Last 7 Days": "اخر ٧ ايام",
    "Last 14 Days": "آخر ١٤ يومًا",
    "Last 30 Days": "آخر ٣٠ يومًا",
    "Qualification": "مؤهل",
    "Matriculation": "شهادة الثانوية العامة",
    "Intermidiate": "متوسط",
    "Graduate": "متخرج",
    "Showing 1 - 10 of 34 results": "عرض ١-١٠ من ٣٤ نتيجة",
    "Sort by": "ترتيب",
    "Most Recent": "الأحدث",
    "Name": "اسم",
    // "Salary": "مرتب",
    "ago": "منذ",
    "Days": "أيام",
    "Days0": "يوم",
    "Days1": "يومان",
    "Days2": "يومًا",
    "Jobs": "وظائف",
    "Vacancies": "أماكن شاغرة",
    // "Apply Now": "قدم الآن",
    "For Teachers": "للـمـدرسـين",
    "Get notified for every new job.": "احصل على إشعارات مع كل وظيفة جديدة",
    "Create and build your profile": "آنشئ ملفك الشخصي",
    "News, Tips & Articles": "الأخبار، النصائح والمقالات",
    "Made for Schools.": "صنعت من أجل المدارس",
    "Join our school hub and list your job vacancies": "انضم إلى مركز مدرستنا وقم بإدراج الوظائف الشاغرة الخاصة بك",
    "Search and filter applicants": "قم بالبحث والتصفية بين المتقدمين",
    "Manage your job postings": "أدر الوظائف التي قمت بنشرها",
    "View reports and analytics": 'اطلع على التقارير والتحليلات',
    "Register Now": 'سجل الآن',
    "Get New Job Notifications": "احصل على إشعارات الوظائف الجديدة",
    "Subscribe & get all related jobs notification": "اشترك، واحصل على اشعارات بكل ما يخص الوظائف",
    "Subscribe": "اشتراك",
    "Enter your email": "أدخل بريدك الإلكتروني",
    "For Candidate": "للمرشحين",
    "Quick Links": "روابط سريعة",
    "Information": "معلومات",
    "Browse Jobs": "تصفح الوظائف",
    "Account": "الحساب",
    "Home": "الرئيسية",
    "About": "من نحن",
    "Pricing": "التسعير",
    "Privacy": "الخصوصية",
    "Contact": "تواصل معنا",
    "Phone": "الهاتف",
    "Email": "البريد الإلكتروني",
    "Address": "العنوان",
    'About Us': "من نحن؟",
    "FAQ": 'الأسئلة المتكررة',
    "Popular Post": "منشور مشهور",
    "Tags": "العلامات",
    "May 8, 2021": '٨ مايو ٢٠٢١',
    "May 5, 2021": '٥ مايو ٢٠٢١',
    "April 20, 2021": "٢٠ أبريل ٢٠٢١",
    "Economy Growth is Being Increased by IT Sectors": "يزداد نمو الاقتصاد من قبل قطاعات تكنولوجيا المعلومات",
    "April 28, 2021": "٢٨ أبريل ٢٠٢١",
    "10 Things You Should Know Before Apply": "10 أشياء يجب أن تعرفها قبل التقديم",
    "Web Design": "تصميم المواقع",
    "Job Tips": "نصائح وظيفية",
    "UX Design": "تصميم UX",
    "Tips & Tricks": "نصائح وخدع",
    "Writting": "الكتابة",
    "Business": "عمل",
    "Resume": "سيرة ذاتية",
    "5": "٥",
    "8": "٨",
    "4": "٤",
    "12": "١٢",
    "7": "٧",
    "Tips for Making Your Resume Stand Out": "نصائح لإبراز سيرتك الذاتية",
    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.": "هناك العديد من الأشكال المتوفرة لنصوص لوريم إيبسوم ، لكن الغالبية قد تعرضت للتغيير بشكل ما ، عن طريق إدخال بعض الفكاهة أو الكلمات العشوائية التي لا تبدو قابلة للتصديق إلى حد ما.",
    "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock.": "خلافًا للاعتقاد الشائع ، فإن Lorem Ipsum ليس مجرد نص عشوائي. لها جذور في قطعة من الأدب اللاتيني الكلاسيكي من ٤٥ قبل الميلاد ، مما يجعلها أكثر من ٢٠٠٠ عام. ريتشارد مكلينتوك.",
    "Develpment": "التطور",
    "Leave a Reply": "اترك رد",
    "Comment": "تعليق",
    "Your Comment": "تعليقك",
    "Post a Comment": "انشر تعليق",
    'Puplished on': "تاريخ النشر",
    "Vacancy": "الأماكن الشاغرة",
    "Employment Status": "حالة الوظيفة",
    "Experience": "الخبرة",
    "Job Location": "العنوان",
    "Salary": "الراتب",
    "Gender": "الجنس",
    "Application Deadline": "تاريخ الإنتهاء",
    // "Job Location": "عنوان الوظيفة",
    "Job Description": "الوصف",
    "Responsibilities": "المهام",
    "Education + Experience": "التعليم + الخبرات",
    "Benefits": "الامتيازات",
    "SCHOOLS": "المدارس",
    // "TEACHERS": "معلمون",
    "Search Blog": "مدونة البحث",
    "Blog Details": "تفاصيل المقالة",
    "TEACHERS": "المدرسين",
    "Search School": "ابحث عن المدرسة",
    "Search Teacher": "ابحث عن مدرس",
    "Academies" : "الأكاديميات",
    "NO DATA HERE": "لا توجد بيانات هنا",
    "Sent!": "أرسلت",
    "Please login again to continue": "الرجاء تسجيل الدخول مرة أخرى للمتابعة",
    "Something Went Wrong!": "هناك خطأ ما",
    "Enter your login data": "أدخل بيانات تسجيل الدخول الخاصة بك",
    "Error While Trying to Login With Linkedin": "خطأ أثناء محاولة تسجيل الدخول باستخدام ينكدين",
    "Error While Trying to Login With Facebook": "خطأ أثناء محاولة تسجيل الدخول باستخدام فيسبوك",
    "Error While Trying to Login With Google": "خطأ أثناء محاولة تسجيل الدخول باستخدام جوجل",
    "You Logged In Successfully": "لقد قمت بتسجيل الدخول بنجاح",
    "Enter email": "أدخل البريد الإلكتروني",
    "Email has been sent successfully": "تم ارسال البريد الالكتروني بنجاح",
    "The two passwords don't match": "كلمتا المرور غير متطابقتين",
    "Academy Name is required": "اسم الاكاديمية مطلوب",
    "Organization Type is required": "نوع المؤسسة مطلوب",
    "Contact Number is required": "رقم الاتصال مطلوب",
    "Country is required": "الدولة مطلوبة",
    "City is required": "المدينة مطلوبة",
    "Academy description is required": "مطلوب وصف الأكاديمية",
    "Address is required": "العنوان مطلوب",
    "Avatar is required": "الصورة الرمزية مطلوبة",
    "Academy type is required": "نوع الأكاديمية مطلوب",
    "Please upload your academy files": "يرجى تحميل ملفات الأكاديمية الخاصة بك",
    "International Accreditation status required": "حالة الاعتماد الدولي المطلوبة",
    "International Accreditation bodies required": "هيئات الاعتماد الدولية المطلوبة",
    "Academy Form Submitted Successfully": "تم تقديم نموذج الأكاديمية بنجاح",
    'File uploaded successfuly': "تم تحميل الملف بنجاح",
    'Error while uploading files': "خطأ أثناء تحميل الملفات",
    "Please enter your first name": "يرجى ادخال الاسم الاول",
    "Please enter your last name": "الرجاء إدخال اسمك الأخير",
    "Please enter your number": "الرجاء إدخال رقمك",
    "Please tell us where do you live": "من فضلك اخبرنا اين تعيش",
    "Please add your fluent languages": "يرجى إضافة لغاتك بطلاقة",
    "Please add your citizenship": "الرجاء إضافة جنسيتك",
    "Please add an education degree": "الرجاء إضافة درجة علمية",
    "Please select a vaild job title": "الرجاء تحديد مسمى وظيفي صالح",
    "Teaching License Form ": "استمارة رخصة التدريس ",
    " Please add grades level": " الرجاء إضافة مستوى الدرجات",
    " Please add subjects": " الرجاء إضافة الموضوعات",
    " start date and end date can't be the same": " لا يمكن أن يكون تاريخ البدء وتاريخ الانتهاء متطابقين",
    " start date can't come after the end date": " لا يمكن أن يأتي تاريخ البدء بعد تاريخ الانتهاء",
    " License type is required": " نوع الترخيص مطلوب",
    " Place of Issurance is required": " مكان الاصدار مطلوب",
    " Country is required": " الدولة مطلوبة",
    " curriculum you tought is required": " المنهج الدراسي الذي تدرسه مطلوب",
    "Please add an Teaching License": "الرجاء إضافة رخصة تدريس",
    "Education Form ": 'استمارة التعليم ',
    " Role Title is required": " مطلوب لقب الدور",
    " Place of Issurance is required": " مكان الاصدار مطلوب",
    " Country is required": " الدولة مطلوبة",
    " curriculum you tought is required": " المنهج الدراسي الذي تدرسه مطلوب",
    " Please add grades level": " الرجاء إضافة مستوى الدرجات",
    " Please add subjects": " الرجاء إضافة الموضوعات",
    "Please add an Experience": "الرجاء إضافة تجربة",
    " Degree level is required": " مستوى الشهادة مطلوب",
    " Education field is required": ' مجال التعليم مطلوب',
    " Place of issurance is required": " مكان الاصدار مطلوب",
    "Teacher Profile Created Successfully": "تم إنشاء ملف تعريف المعلم بنجاح",
    "Teacher Education Updated Successfully": "تم تحديث تعليم المعلم بنجاح",
    "Teacher Experience Updated Successfully": "تم تحديث تجربة المعلم بنجاح",
    "Teaching License Updated Successfully": "تم تحديث رخصة التدريس بنجاح",
    "Password and confirm password does not match": "كلمة المرور وتأكيد كلمة المرور غير متطابقين",
    "Welcome!": "أهلا وسهلا",
    "English": "إنجليزي",
    "Specialize in": "متخصص في"
}