/* eslint-disable */

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NoDataFound from '../../ui/noDataFound/NoDataFound';
import Spinner from '../../ui/spinner/Spinner';

const Testimonial = ({ about, loading }) => {

    if (loading) {
        return (
            <Spinner />
        )
    }
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    useEffect(() => {
        $('.testimonial-slider-two').owlCarousel({
            dots: false,
            nav: true,
            navText: [
                "<i class='bx bx-chevrons-left bx-tada'></i>",
                "<i class='bx bx-chevrons-right bx-tada'></i>"
            ],
            items: 2,
            loop: true,
            smartSpeed: 2500,
            autoplay: true,
            autoplayTimeout: 4000,
            responsive: {
                0: {
                    items: 1,
                },
                768: {
                    items: 2,
                }
            },
            margin: 30,
        })
    })

    return (
        <div className="testimonial-style-two ptb-100">
            <div className="container">
                <div className="section-title text-center">
                    <h2>{t("What Client’s Say About Us")}</h2>
                    <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus")}</p>
                </div>
                <div className="testimonial-slider-two owl-carousel owl-theme">
                    {
                        lng === 'arab' ?
                            about?.data?.data?.length === 0 ?
                                <NoDataFound />
                                :
                                about?.data?.data?.map(item => (
                                    <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }} className="testimonial-items" key={item.id}>
                                        <div className="testimonial-text">
                                            <i className='flaticon-left-quotes-sign'></i>
                                            <p>{item?.ar_body || "No Body"}</p>
                                        </div>

                                        <div className="testimonial-info-text">
                                            <h3>{item?.ar_name || "No Name"}</h3>
                                            <p>{item?.ar_title || "No Title"}</p>
                                        </div>
                                    </div>
                                ))
                            :
                            about?.data?.testimonial?.length === 0 ?
                                <NoDataFound />
                                :
                                about?.data?.testimonial?.map(item => (
                                    <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }} className="testimonial-items" key={item.id || 0}>
                                        <div className="testimonial-text">
                                            <i className='flaticon-left-quotes-sign'></i>
                                            <p>{item?.body || "No Body"}</p>
                                        </div>

                                        <div className="testimonial-info-text">
                                            <h3>{item?.name || "No Name"}</h3>
                                            <p>{item?.title || "No Title"}</p>
                                        </div>
                                    </div>
                                ))
                    }
                </div>

            </div>
        </div>
    )
}

export default Testimonial