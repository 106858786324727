import React from 'react'
import ReusableBanner from '../ui/reusableBanner/ReusableBanner'
import ContactForm from './contactForm/ContactForm'
import ContactSection from './contactSection/ContactSection'
import { useTranslation } from 'react-i18next'

const ContactUs = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;

    return (
        <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }}>
            <ReusableBanner title={t("Contact Us")} />
            <ContactSection />
            <ContactForm />
        </div>
    )
}

export default ContactUs