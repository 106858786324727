import React, { useEffect } from 'react'
import ReusableBanner from '../ui/reusableBanner/ReusableBanner'
import Accordion from './accordion/Accordion'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import { getFAQ } from '../../redux/actions/faq_page'

const Faq = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch()

  const { faq, loading } = useSelector(state => state.faq);

  useEffect(() => {
    dispatch(getFAQ(lng === 'arab' ? 2 : 1))
  }, [dispatch, lng])

  return (
    <div>
      <ReusableBanner title={t("FAQ")} bacgroundclassName="faqBC" />
      <Accordion {...{ faq, loading }} />
    </div>
  )
}

export default Faq