import { FETCH_FAQ, FAQ_LOADED, LOAD_FAQ } from '../constants/actionTypes'
import * as api from '../../api'

export const getFAQ = (lang) => async (dispatch) => {
    dispatch({ type: LOAD_FAQ });
    try {
        const { data } = await api.getFAQ(lang);
        dispatch({ type: FETCH_FAQ, payload: data?.data })
    } catch (error) {
        dispatch({ type: FAQ_LOADED })
    }
}