import axios from "axios";

export const baseURL = "https://api.madaresweb.mvp-apps.ae/api/website";
export const base_API_URL = "https://api.madaresweb.mvp-apps.ae/api/";
//----------Home Page------------------

export const getHPBanner = (langNumber) =>
  axios.get(`${base_API_URL}banner?lang=${langNumber}`);

// we will use articles for home page, blog page, and article page.
export const getHPArticles = (langNumber) =>
  axios.get(`${base_API_URL}article?lang=${langNumber}`);
export const getHPSubjects = (langNumber) =>
  axios.get(`${base_API_URL}popularSubjects?lang=${langNumber}`);
export const getHPJobLevel = (langNumber) =>
  axios.post(`${baseURL}/joblevel?lang=${langNumber}`);
export const getHPAvailableJobs = (langNumber) =>
  axios.post(`${baseURL}/AvailableJobs?lang=${langNumber}`);

//----------FAQ Page------------------

export const getFAQ = (langNumber) =>
  axios.get(`${base_API_URL}FAQs?lang=${langNumber}`);

//----------About Page------------------

export const getAbout = (langNumber) =>
  axios.get(`${base_API_URL}aboutStatistics?lang=${langNumber}`);

//----------Teacher Page------------------

export const getTeachers = (langNumber) =>
  axios.post(`${baseURL}/teacher?lang=${langNumber}`);

//----------School Page------------------

export const getSchools = (langNumber) =>
  axios.post(`${baseURL}/acadmy?lang=${langNumber}`);

//----------Job Page------------------

// export const getJobs = (
//   pageNumber,
//   langNumber,
//   filters,
//   search,
//   experience,
//   postDate,
//   salaryRange,
//   teacher_categories
// ) =>
//   axios.post(
//     `https://api.madaresweb.mvp-apps.ae/api/activeJobs?page=${pageNumber}&lang=${langNumber}&search=${search}&filters[subject]=${
//       filters || ""
//     }&filters[experience]=${experience || ""}&filters[post_date]=${
//       postDate || ""
//     }&filters[minimum_salary]=${
//       salaryRange?.min || 0
//     }&filters[maximum_salary]=${
//       salaryRange?.max || 10000
//     }&filters[teacher_categories[${teacher_categories}]]`
//   );

//----------Available Jobs------------------

export const getAvailableJobs = (langNumber) =>
  axios.get(
    `https://api.madaresweb.mvp-apps.ae/api/availableJobs?lang=${langNumber}`
  );

//----------getBlog------------------

export const getBlog = (langNumber) =>
  axios.post(`${baseURL}/blog?lang=${langNumber}`);

//----------Job Details------------------

export const getJobDetails = (jobID, lang) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/jobDetails?job_id=${jobID}&lang=${lang}`
  );
// login

export const Login = async (email, pssd) => {
  let finalFormData = new FormData();
  finalFormData.append("email", email);
  finalFormData.append("password", pssd);
  return await axios({
    method: "post",
    url: "https://api.madaresweb.mvp-apps.ae/api/auth/login",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const socialLogin = async (loginType, accessToken) =>
  await axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/auth/socialLogin?loginType=${loginType}&accessToken=${accessToken}`
  );

export const forgotPassword = (email) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/auth/forgetPassword?email=${email}`
  );

export const signUp = async (email, pssd) => {
  let finalFormData = new FormData();
  finalFormData.append("email", email);
  finalFormData.append("password", pssd);

  return await axios({
    method: "post",
    url: "https://api.madaresweb.mvp-apps.ae/api/auth/signup",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const getUserInfo = (lng) =>
  axios.post(`https://api.madaresweb.mvp-apps.ae/api/getFlags`);

export const checkValidation = (email) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/check_verification?email=${email}`
  );

export const reSendVerf = (email, pssd) =>
  axios.post(
    `https://api.madaresweb.mvp-apps.ae/api/auth/reSendVerificationEmail?email=${email}`
  );

export const resetPassword = async (password, password_confirmation, token) => {
  let finalFormData = new FormData();
  finalFormData.append("password", password);
  finalFormData.append("password_confirmation", password_confirmation);
  finalFormData.append("token", token);
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/auth/reSetPassword`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const logoutAPI = () =>
  axios.post(`https://api.madaresweb.mvp-apps.ae/api/auth/logout`);

export const increaseViews = (job_id) =>
  axios.post(`${base_API_URL}increaseViews?job_id=${job_id}`);

export const applyForJob = (job_id) =>
  axios.post(`${base_API_URL}applyForJob?job_id=${job_id}`);

export const saveJob = (job_id) =>
  axios.post(`${base_API_URL}saveJob?job_id=${job_id}`);

export const unSaveJob = (job_id) =>
  axios.post(`${base_API_URL}unSaveJob?job_id=${job_id}`);

export const getAcademyDetails = (id) =>
  axios.get(`${base_API_URL}academyDetails?academy_id=${id}`);

export const getSubjectDetails = (id) =>
  axios.get(`${base_API_URL}jobsBySubject?subject_id=${id}`);

export const getMaximumSalary = () => axios.get(`${base_API_URL}maxSalary`);

export const getPopularJobTitle = (lang) =>
  axios.get(`${base_API_URL}popularJobTitles?lang=${lang}`);

export const getJobs = async (
  pageNumber,
  langNumber,
  search,
  filters,
  experience,
  postDate,
  salaryRange,
  teacher_categories
) => {
  let finalFormData = new FormData();
  finalFormData.append("page", pageNumber);
  finalFormData.append("lang", langNumber);
  finalFormData.append("search", search);
  finalFormData.append("filters[subject]", filters);
  finalFormData.append("filters[experience]", experience);
  finalFormData.append("filters[post_date]", postDate);
  finalFormData.append("filters[minimum_salary]", salaryRange.min);
  finalFormData.append("filters[maximum_salary]", salaryRange.max);
  if (teacher_categories.length > 0) {
    finalFormData.append("filters[teacher_categories][]", teacher_categories);
  }

  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/activeJobs`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};
