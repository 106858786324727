import React from 'react'
import './JobFilterModal.css'
import Modal from 'react-bootstrap/Modal'
import JobsFilter from '../JobsFilter'

const JobFilterModal = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  return (
    <Modal
      {...props}
      size="lg"
      centered
    >
      <Modal.Body className={lng === 'arab' ? 'forceArabicFont' : 'forceEnglishFont'} style={{ direction: lng === 'arab' ? 'rtl' : 'ltr', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div className='modalHeader'>
          <i onClick={() => props.onHide()} class='bx bx-x'></i>
        </div>
        <JobsFilter fromPopUp={true} />
      </Modal.Body>
    </Modal>
  );
}

export default JobFilterModal