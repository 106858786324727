import React, { useState, useEffect } from "react";
import "./JobOpenings.css";
import ReusableBanner from "../ui/reusableBanner/ReusableBanner";
import JobsList from "./jobs_list/JobsList";
import JobsFilter from "./jobs_filter/JobsFilter";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMaxSalary } from "../../redux/actions/maximum_salary";
import { getPopularJob } from "../../redux/actions/popular_jobs";

const JobOpenings = ({ title, filters }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    dispatch(getMaxSalary())
    dispatch(getPopularJob(lng === "arab" ? 2 : 1))
  }, [])
  

  const { maxSalary, loading } = useSelector(state => state.maxSalary)
  const [inputSearch, setInputSearch] = useState("");
  const [experience, setExperience] = useState("");
  const [postDate, setPostDate] = useState("");
  const [salaryRange, setSalaryRange] = useState()
  const [teacherCategory, setTeacherCategory] = useState([])
  const { popularJobs } = useSelector(
    (state) => state.popularJobTitles
  );
  useEffect(() => {
    if(maxSalary.length > 0) { 
      console.log("maxSalary", maxSalary)
      setSalaryRange({ min: 0, max: maxSalary })
    }
  }, [loading])
  

  return (
    <div
      style={{ direction: lng === "arab" ? "rtl" : "ltr" }}
      className="pages_with_header"
    >
      <ReusableBanner title={title || t("JOB OPENINGS")} />
      <div className="all_jibs_wrapper">
        <JobsList
          filters={filters}
          inputSearch={inputSearch}
          experience={experience}
          postDate={postDate}
          salaryRange={salaryRange}
          teacherCategory={teacherCategory}
        />
        {width > 670 ? (
          <JobsFilter
            setInputSearch={setInputSearch}
            inputSearch={inputSearch}
            experience={experience}
            setExperience={setExperience}
            postDate={postDate}
            setPostDate={setPostDate}
            salaryRange={salaryRange}
            setSalaryRange={setSalaryRange}
            teacherCategory={teacherCategory}
            setTeacherCategory={setTeacherCategory}
            popularJobs={popularJobs}
          />
        ) : null}
      </div>
    </div>
  );
};

export default JobOpenings;
