import React from 'react'
import Banner from './banner/Banner'
import PopularSubs from './popularSubs/PopularSubs'
import AvailableJobs from './availableJobs/AvailableJobs'
import JobTypes from './jobTypes/JobTypes'
import CreateBuild from './createNbuild/CreateBuild'
import News from './news/News'
import ForSchools from './forSchools/ForSchools'
import { useSelector } from 'react-redux'
import './Home.css'
const Home = () => {

  return (
    <div className='home_page'>
      <Banner />
      {/* <PopularSubs />
      <AvailableJobs />
      <JobTypes />
      <CreateBuild />
      <News />
      <ForSchools /> */}
    </div>
  )
}

export default Home