import React from 'react'
import { useTranslation } from 'react-i18next'

const NoDataFound = () => {
    const { t } = useTranslation();

    return (
        <div style={{ textAlign: 'center', padding: "10%" }}>{t("NO DATA HERE")}</div>
    )
}

export default NoDataFound