import React, { useEffect, useState } from "react";
import "./JobsFilter.css";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const JobsFilter = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <div className="jobs_list_filter">
      <div className="job_filter_box">
        <label
          style={{ textAlign: lng === "arab" ? "right" : "left" }}
          className="filter_title"
        >
          {t("Search Keywords")}
        </label>
        <div className="uk-margin">
          <input
            className="uk-input"
            type="text"
            placeholder={t("e.g. web design")}
            value={props?.inputSearch}
            onChange={(e) => props?.setInputSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="job_filter_box">
        <label
          style={{ textAlign: lng === "arab" ? "right" : "left" }}
          className="filter_title"
        >
          {t("Teacher Category")}
        </label>
        <div className="uk-margin">
          <Select
            name="types"
            onChange={(e) => props?.setTeacherCategory(e)}
            value={props?.teacherCategory}
            options={props?.popularJobs?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
            isMulti
          />
        </div>
      </div>
      {/* <div className='job_filter_box'>
              <label style={{ textAlign: lng === 'arab' ? 'right' : 'left' }} className='filter_title'>{t('Location')}</label>
              <div className="uk-margin">
                  <input className="uk-input" type="text" placeholder={t('Location')} />
              </div>
          </div> */}
      <div className="job_filter_box">
        <label
          style={{ textAlign: lng === "arab" ? "right" : "left" }}
          className="filter_title"
        >
          {t("Salary Range")}
        </label>
        <div style={{ direction: "ltr" }} className="uk-margin">
          <InputRange
            value={props?.salaryRange}
            step={2}
            maxValue={10000}
            minValue={0}
            onChange={(value) => props?.setSalaryRange(value)}
          />
        </div>
      </div>
      <div className="job_filter_box">
        <label
          style={{ textAlign: lng === "arab" ? "right" : "left" }}
          className="filter_title "
        >
          {t("Experince")}
        </label>
        <div
          style={{ alignItems: lng === "arab" ? "flex-start" : "flex-start" }}
          className="uk-margin filter_form_radio_wrraper"
        >
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              defaultChecked
              onChange={() => props?.setExperience("")}
            />{" "}
            {t("Any Experince")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              onChange={() => props?.setExperience(1)}
            />{" "}
            {t("1 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              onChange={() => props?.setExperience(2)}
            />{" "}
            {t("2 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              onChange={() => props?.setExperience(3)}
            />{" "}
            {t("3 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              onChange={() => props?.setExperience(4)}
            />{" "}
            {t("4 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              onChange={() => props?.setExperience(5)}
            />{" "}
            {t("5 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              onChange={() => props?.setExperience(6)}
            />{" "}
            {t("6 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              onChange={() => props?.setExperience(7)}
            />{" "}
            {t("7 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              onChange={() => props?.setExperience(8)}
            />{" "}
            {t("8 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              onChange={() => props?.setExperience(9)}
            />{" "}
            {t("9 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              onChange={() => props?.setExperience(10)}
            />{" "}
            {t("10 Year")}
          </label>
        </div>
      </div>
      <div className="job_filter_box">
        <label
          style={{ textAlign: lng === "arab" ? "right" : "left" }}
          className="filter_title"
        >
          {t("Date Posted")}
        </label>
        <div
          style={{ alignItems: lng === "arab" ? "flex-start" : "flex-start" }}
          className="uk-margin filter_form_radio_wrraper"
        >
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio2"
              defaultChecked
              onChange={() => props?.setPostDate("")}
            />{" "}
            {t("Any Date")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio2"
              onChange={() => props?.setPostDate("D")}
            />{" "}
            {t("Last 24 Hours")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio2"
              onChange={() => props?.setPostDate("W")}
            />{" "}
            {t("Last 7 Days")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio2"
              onChange={() => props?.setPostDate("HM")}
            />{" "}
            {t("Last 15 Days")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio2"
              onChange={() => props?.setPostDate("M")}
            />{" "}
            {t("Last 30 Days")}
          </label>
        </div>
      </div>
      {/* <div className='job_filter_box'>
              <label style={{ textAlign: lng === 'arab' ? 'right' : 'left' }} className='filter_title'>{t('Qualification')}</label>
              <div style={{ alignItems: lng === 'arab' ? 'flex-start' : 'flex-start' }} className="uk-margin filter_form_radio_wrraper">
                  <label><input className="uk-radio" type="radio" name="radio2" defaultChecked />{t('Matriculation')}</label>
                  <label><input className="uk-radio" type="radio" name="radio2" /> {t('Intermidiate')}</label>
                  <label><input className="uk-radio" type="radio" name="radio2" /> {t('Graduate')}</label>
              </div>
          </div> */}
      {props.fromPopUp ? (
        <button className="uk-button uk-button-default" type="button">
          {t("Filter")}
        </button>
      ) : null}
    </div>
  )
};

export default JobsFilter;
