import React, { useEffect, useState } from "react";
import Login from "./subComponents/Login";
import SignUp from "./subComponents/SignUp";
import ForgotPassword from "./subComponents/ForgotPassword";
const RegularAuth = (props) => {
  const [view, setView] = useState(0);
  useEffect(() => {
    if (props.localNavigationState?.hasOwnProperty('view')) {
      setView(props.localNavigationState?.view)
    }
  }, [props.localNavigationState])
  return (
      <>
      {view === 0?
      <Login setView={setView} setLoadingModalShow={props.setLoadingModalShow}/>:
      view === 1?
      <SignUp setView={setView} setLoadingModalShow={props.setLoadingModalShow}/>:
      <ForgotPassword setView={setView} setLoadingModalShow={props.setLoadingModalShow}/>
    }
      </>
  );
};

export default RegularAuth;