import React, { Fragment, useState } from "react";
import loginImg from "../../../../../assets/authBC.png";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import LoadingModal from "../../../../../layouts/LoadingPopUp";
import { toastAlert } from "../../../../../layouts/nav/toastAlert";
import { forgotPassword } from "../../../../../redux/actions/Auth";

const ForgotPassword = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [loadingModalShow, setLoadingModalShow] = useState();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleForgotPassword = (e) => {
    e.preventDefault();
    props.setLoadingModalShow(true);
    if (!email) {
      props.setLoadingModalShow(false);
      toastAlert(t("Enter email"), undefined, "warning");
    } else {
      dispatch(forgotPassword(email, props.setLoadingModalShow, history));
    }
  };
  return (
    <Fragment>
      <div className="login_page">
        <img src={loginImg} alt="login_img" className="login_img" />
        <div
          className="login_card_wrapper"
          style={{ direction: lng === "arab" ? "rtl" : "ltr" }}
        >
          <h3 className="page_title">{t("Forgot Password")}</h3>
          <div className="cardd overflow-hidden">
            <div
              style={{ padding: "20px" }}
              className="card-body innerCardBody"
            >
              <div className="green-bg" />

              <form onSubmit={handleForgotPassword} className="login_form">
                <div className="regular_login">
                  <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                    <label className="text-label">{t("Email")}</label>
                    <input
                      required
                      type="email"
                      className="form_control"
                      placeholder={t("Email")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div
                    className="col-12"
                    style={{ display: "flex", justifyContent: "space-between", flexWrap:'wrap', gap:'10px', padding: '20px 0px 20px 0px' }}
                  >
                    <Link
                      id="loginSubmit"
                      to={{pathname: "/authentication", state: { view: 0 }}}
                      // onClick={() => history.push('/authentication', { view: 0 })}
                      // type="button"
                      className="widthMedia btn col-md-4 col-sm-4 col-lg-4"
                      style={{
                        backgroundColor: "transparent",
                        color: "#104b08",
                        border: '1px solid #104b08',
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      {t("Back")}
                    </Link>
                    <button
                      id="loginSubmit"
                      type="submit"
                      className="widthMedia btn col-md-4 col-sm-4 col-lg-4"
                      style={{
                        backgroundColor: "#104b08",
                        color: "white",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
          onHide={() => setLoadingModalShow(false)}
        />
      </div>
    </Fragment>
  );
};

export default ForgotPassword;