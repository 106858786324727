import { t } from 'i18next';
import React, { useEffect, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
// import './index.css';
import { toastAlert } from '../../../../layouts/nav/toastAlert';

const ManyFilePicker = ({ children, setAcademyFiles, academyFiles, academyFilesInfo, setAcademyFilesInfo }) => {
    const [updatedAcademyFiles, setUpdatedAcademyFiles] = useState(academyFiles?.slice())
    const [filesInfo, setFilesInfo] = useState([])

    useEffect(() => {
        setUpdatedAcademyFiles(academyFiles?.slice())
    }, [academyFiles])
    useEffect(() => {
        setFilesInfo(academyFilesInfo?.slice())
    }, [academyFilesInfo])
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            console.log(file)
            const reader = new FileReader()
            reader.readAsDataURL(file);
            reader.onload = function () {
                let array = updatedAcademyFiles?.slice()
                let infoArr = filesInfo?.slice()
                array.push(file)
                infoArr.push(`${file.path} - ${file.size} bytes`)
                setAcademyFilesInfo([...infoArr])
                setAcademyFiles([...array])
                toastAlert(t('File uploaded successfuly'), undefined, "success");
            };
            reader.onerror = function (error) {
                toastAlert(t('Error while uploading files'), undefined, "warning");
            };
        })
    }, [updatedAcademyFiles])
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const files = filesInfo?.map((item, i) => (
        <li key={i}>
            {item}
        </li>
    ));


    return (
        <>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {children}
            </div>
            <aside>
                <ul>{files}</ul>
            </aside>
        </>
    );
};

export default ManyFilePicker;