import React, { Fragment, useState, useEffect } from 'react'
import loginImg from "../../../../assets/authBC.png";
import { t } from 'i18next'
import LoadingModal from "../../../../layouts/LoadingPopUp";
import { toastAlert } from "../../../../layouts/nav/toastAlert";
import { useHistory } from 'react-router-dom'
import { resetPassword } from '../../../../redux/actions/Auth';
import { useDispatch } from 'react-redux'
import {useLocation} from "react-router-dom";

const ResetPassword = (props) => {
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const [loadingModalShow, setLoadingModalShow] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const dispatch = useDispatch()
    const history = useHistory()
    const search = useLocation().search;
    const tokenFromUrl = new URLSearchParams(search).get('t');
    useEffect(() => {
        console.log(tokenFromUrl)
    }, [tokenFromUrl])
    const handleResetPassword = (e) => {
        e.preventDefault()
        setLoadingModalShow(true)
        if (!password || !confirmPassword) {
            setLoadingModalShow(false)
            toastAlert(t('Enter email'), undefined, "warning")
        } else if (password !== confirmPassword) {
            setLoadingModalShow(false)
            toastAlert(t('Password and confirm password does not match'), undefined, "success")
        }
        else {
            dispatch(resetPassword(password, confirmPassword, tokenFromUrl, setLoadingModalShow, history, setShowMessage))
        }
    }
    return (
        <Fragment>
            <div className="login_page">
                <img src={loginImg} alt='login_img' className='login_img' />
                <div className='login_card_wrapper' style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }}>
                    <h3 className="page_title" >{t("Reset Password")}</h3>
                    <div className='cardd overflow-hidden'>
                        <div style={{ padding: '40px' }} className="card-body innerCardBody">
                            <div className="green-bg" />
                            <form onSubmit={handleResetPassword} className="login_form">
                                {showMessage ?
                                    <div style={{ minHeight: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='regular_login'>
                                        <h5 style={{ color: 'gray', textAlign: 'center' }}>{t("You Changed Your Password Successfully")}</h5>
                                        <button id='loginSubmit' onClick={() => history.push('/authentication')} type="submit" className="widthMedia btn mb-4 mt-4 col-md-4 col-sm-4 col-lg-4" style={{
                                                  backgroundColor: "transparent",
                                                  color: "#104b08",
                                                  border: '1px solid #104b08',
                                                  padding: "5px",
                                                  borderRadius: "5px",
                                                }}>
                                            {t('Back')}
                                        </button>
                                    </div>
                                    :
                                    <div className='regular_login'>
                                        <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                                            <label className="text-label">{t('Password')}</label>
                                            <input
                                                required
                                                type="password"
                                                className="form-control"
                                                placeholder={t("Password")}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="widthMedia form-group mb-2 col-md-12 col-sm-12 col-lg-12">
                                            <label className="text-label">{t('Re-Enter Password')}</label>
                                            <input
                                                required
                                                type="password"
                                                className="form-control"
                                                placeholder={t("Password")}
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-12' style={{ display: "flex", justifyContent: "space-between", flexWrap:'wrap', gap:'10px', padding: '20px 0px 20px 0px' }}>
                                            <button id='loginSubmit' onClick={() => history.push('/authentication')} type="submit" 
                                                className="widthMedia btn col-md-4 col-sm-4 col-lg-4"
                                                style={{
                                                  backgroundColor: "transparent",
                                                  color: "#104b08",
                                                  border: '1px solid #104b08',
                                                  padding: "5px",
                                                  borderRadius: "5px",
                                                }}
                                            >
                                                {t('Back')}
                                            </button>
                                            <button id='loginSubmit' type="submit" className="widthMedia btn col-md-4 col-sm-4 col-lg-4" 
                                            style={{
                                              backgroundColor: "#104b08",
                                              color: "white",
                                              padding: "5px",
                                              borderRadius: "5px",
                                            }}
                                            >
                                                {t('Submit')}
                                            </button>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
                <LoadingModal
                    show={loadingModalShow}
                    dialogClassName="modal-60w"
                    backdrop="static"
                    onHide={() => setLoadingModalShow(false)}
                />
            </div >
        </Fragment >
    )
}

export default ResetPassword