import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'
import teacherIcon from '../../../../assets/Teacher.svg'
import academyIcon from '../../../../assets/school.svg'
import TeacherRegistration from './TeacherRegistration'
import AcademyReg from './AcademyRegistration'
import { logoutActoin } from '../../../../redux/actions/Auth'
import loginImg from "../../../../assets/authBC.png";

const UserTypeFlow = (props) => {
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const dispatch = useDispatch()
    const [view, setView] = useState(props.authStatus === 3? props.localNavigationState?.hasOwnProperty('view')?  props.localNavigationState?.view:0:1)
    const logOut = () => {
        dispatch(logoutActoin(props.setLoadingModalShow))
    }
    useEffect(() => {
      setView(props.authStatus === 3? props.localNavigationState?.hasOwnProperty('userView')?  props.localNavigationState?.userView:0:1)
    }, [props.authStatus, props.localNavigationState])

    return (
        <>
        {view===0?
          <div className="login_page">
          <img src={loginImg} alt="login_img" className="login_img" />
          <div className="login_card_wrapper" style={{ direction: lng === "arab" ? "rtl" : "ltr" }}>
            <h3 className="page_title">{t('Who are You?')}</h3>
            <div className="cardd overflow-hidden">
              <div style={{ padding: "20px" }} className="card-body innerCardBody">
                <div className="green-bg" />
                <div className="user_type_form">
                    <Link to={{pathname: "/authentication", state: { userView: 1 }}} className='reg_type'>
                        <img src={teacherIcon} alt='teacher'/>
                        <span>{t('Teacher')}</span>
                    </Link>
                    <Link to={{pathname: "/authentication", state: { userView: 2 }}} className='reg_type'>
                        <img src={academyIcon} alt='academy'/>
                        <span>{t('Academy')}</span>
                    </Link>
                </div>
                <div className='haveAnAccount'>
                    <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', padding: '20px 20px 0px 20px' }} className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                        <Link style={{ fontSize: '11px', color: 'gray' }} to="#" onClick={logOut}>{t('Logout')}</Link>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>:
        view === 1?
        <TeacherRegistration authStatus={props.authStatus} setAuthStatus={props.setAuthStatus} setViewOuter={setView} setLoadingModalShow={props.setLoadingModalShow}/>:
        <AcademyReg setViewOuter={setView} setLoadingModalShow={props.setLoadingModalShow}/>}
        </>
    )
}

export default UserTypeFlow