import React, { useEffect, useState } from "react";
import loginImg from "../../../assets/authBC.png";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingModal from "../../../layouts/LoadingPopUp";
import { toastAlert } from "../../../layouts/nav/toastAlert";
import AuthHeader from "../../ui/header/AuthHeader";
import RegularAuth from "./RegularAuth/RegularAuth";
import { useSelector } from "react-redux";
import {useLocation} from "react-router-dom";
import Spinner from "../../ui/spinner/Spinner";
import EmailVerfication from "./emailVerf";
import ResetPassword from "./resetPassword/ResetPassword";
import IsVerf from "./isActive";
import UserTypeFlow from "./userTypeAuth/userType";
import Welcome from "./welcome/Welcome";
const AuthProcess = (props) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const location = useLocation()
  const [loadingModalShow, setLoadingModalShow] = useState(true);
  const [authStatus, setAuthStatus] = useState(0);
  const [historyState, setHistoryState] = useState(0);
  const [localNavigationState, setLocalNavigationState] = useState({});
  const [userTypeNavigationState, setUserTypeNavigationState] = useState({});
  const userToken = useSelector((state) => state?.userDetails?.token);
  const userDetails = useSelector((state) => state?.userDetails?.authData);
  const loading = useSelector((state) => state?.userDetails?.loading);
  const search = useLocation().search;
  const tokenFromUrl = new URLSearchParams(search).get('t');
  let [token, setToken] =useState(tokenFromUrl);
  useEffect(() => {
    setToken(tokenFromUrl)
  }, [tokenFromUrl])
  useEffect(() => {
    if (location?.state?.step) {
      setHistoryState(location?.state?.step)
    }
    if (location?.state?.hasOwnProperty('view')) {
      setLocalNavigationState({view: location?.state?.view})
    }
    if (location?.state?.hasOwnProperty('userView')) {
      setUserTypeNavigationState({userView: location?.state?.userView})
    }
 }, [location, location?.state]);

  const dispatch = useDispatch();
  const history = useHistory();
  let userTokenLocal = localStorage.getItem("sess");
  useEffect(() => {
    userTokenLocal = localStorage.getItem("sess");
  }, [userToken])
  useEffect(() => {
    if (authStatus > 9) {
      return history.push('/')
    }
    if (authStatus === 5) {
      const timeOut = setTimeout(() => {
        window.location.replace(`https://dashboard-madares.mvp-apps.ae/dashboard?t=${userTokenLocal}`)
        // window.location.replace(`http://localhost:3001/dashboard?t=${userTokenLocal}`)
        alert('dashboard')
      }, 2000);
      return () => clearTimeout(timeOut);
    }
  }, [authStatus])
  useEffect(() => {
    if (token && !userTokenLocal) {
      setAuthStatus(6)
      setLoadingModalShow(false)
    } else {
      if (token) {
        return history.push('/authentication')
      }
      if (!userTokenLocal) {
        setAuthStatus(1)
        setLoadingModalShow(false)
      } else {
        if (!loading && Object.keys(userDetails).length) {
          if (userDetails?.is_email_verified === '0') {
            setLoadingModalShow(false)
            return setAuthStatus(4)
          } else if (userDetails?.type === 'A' && userDetails?.is_active === '0') {
            setLoadingModalShow(false)
            return setAuthStatus(2)
          } else if (!userDetails?.type && userDetails?.is_email_verified === '1') {
            setLoadingModalShow(false)
            return setAuthStatus(3)
          } else if (userDetails?.type === 'T' && historyState) {
              setLoadingModalShow(false)
              return setAuthStatus(historyState)
          } else if (userDetails?.type && userDetails?.is_email_verified === '1' && userDetails?.is_active === '1') {
            setLoadingModalShow(false)
            return setAuthStatus(5)
          } else {
            history.push('/')
          }
        }
    }
  }
  }, [userDetails, token, userTokenLocal, loading, historyState])

  return (
      <div className="auth_page">
        <AuthHeader/>
        {authStatus === 0?
        <div className="login_page">
          <img src={loginImg} alt="login_img" className="login_img" />
        </div>:
        authStatus === 1?
        <RegularAuth localNavigationState={localNavigationState} setLoadingModalShow={setLoadingModalShow}/>:
        authStatus === 2?
        <IsVerf setLoadingModalShow={setLoadingModalShow}/>:
        authStatus === 3 || authStatus === 7 || authStatus === 8 || authStatus === 9?
        <UserTypeFlow localNavigationState={userTypeNavigationState} authStatus={authStatus} setAuthStatus={setAuthStatus} setLoadingModalShow={setLoadingModalShow}/>:
        authStatus === 4?
        <EmailVerfication/>:
        authStatus === 5?
        <Welcome name={userDetails?.name}/>:
        authStatus === 6?
        <ResetPassword/>:
        null
        }
        <LoadingModal
          show={loadingModalShow}
          dialogClassName="modal-60w"
          backdrop="static"
          onHide={() => setLoadingModalShow(false)}
        />
      </div>
  );
};

export default AuthProcess;