import React from 'react'
const Spinner = ({height, width}) => {
    return (
        <div className="text-center" style={{ padding: "10%" }}>
            <div className="spinner-border" role="status" style={{ width: width || "3rem", height: height || "3rem", color: "#a2c563" }}>
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export default Spinner