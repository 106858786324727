import React from 'react'
import { useTranslation } from 'react-i18next'
import { translate } from '../../../helper/index'
import Spinner from '../spinner/Spinner'
import NoDataFound from '../noDataFound/NoDataFound'

const List = ({ school, loading, search, type, teacher }) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    return (
        <section className="teacher pt-100 pb-70 blog-style-two">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-12 order-lg-2">
                        <div className="row">
                            <div className="col-lg-12 p-3">
                                <form className="border p-5">
                                    <fieldset>
                                        <h4>{t(search)}</h4>
                                        <div className="mb-3">
                                            <input type="text" id="disabledTextInput" className="form-control" placeholder={t("Keyword")} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">{t("Category")}</label>
                                            <select className="form-control">
                                                <option>{t("Any Category")}</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label mt-2">{t("Location")}</label>
                                            <input type="text" className="form-control" placeholder={t("Location")} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">{t("Company Sizes")}</label>
                                            <select className="form-control">
                                                <option>{t("All Company Sizes")}</option>
                                            </select>
                                        </div>
                                        <div className="d-grid">
                                            <button className="btn mt-4" type="submit" style={{ backgroundColor: "#db8307", color: "white" }}>{t("Search")}</button>
                                        </div>
                                    </fieldset>
                                </form>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-sm-12 order-lg-1">
                        {
                            loading ?
                                <Spinner />
                                :
                                <div className="row">
                                    {
                                        type === "teacher" ?
                                            teacher?.data?.length === 0 ?
                                            <NoDataFound />
                                            :
                                            teacher?.data?.map(item => (
                                                <div className="col-lg-4 col-sm-6 p-3" key={item.id}>
                                                    <div className="d-flex align-items-center flex-column border p-3 my-shadow">
                                                        <div className="logo">
                                                            <img src={require("../../../assets/img/teacher/111.jpg")} alt="company logo" className="rounded-circle w-100 h-100" />
                                                        </div>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <p style={{ fontWeight: '900', fontSize: '14px' }} className="p"> {translate(item.name)} </p>
                                                            <p style={{ fontWeight: '600', fontSize: '12px' }} className="p"> {translate(item.profession)} </p>
                                                            <p style={{ fontWeight: '200', fontSize: '11px' }} className="p"> {translate(item.experience)} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            school?.data?.length === 0 ?
                                                <NoDataFound />
                                                :
                                                school?.data?.map((item, index) => (
                                                    <div className="col-lg-4 col-sm-6 p-3" key={index}>
                                                        <div className="d-flex align-items-center flex-column border p-3 my-shadow">
                                                            <div className="logo">
                                                                {/* TODO:
                                                        later change src={ require("../../../assets/img/teacher/111.jpg") || item.avatar } to src={ item.avatar || require("../../../assets/img/teacher/111.jpg")} */}
                                                                <img src={require("../../../assets/img/teacher/111.jpg") || item.avatar} alt="company logo" className="rounded-circle w-100 h-100" />
                                                            </div>
                                                            <div style={{ textAlign: 'center' }}>
                                                                <h5 style={{ fontWeight: '900', fontSize: '14px' }} className="fw-bold"> {item?.ar_name || item?.en_name || "name"} </h5>
                                                                <p style={{ fontWeight: '600', fontSize: '12px' }}> {item?.ar_city || item?.en_city || "city"} </p>
                                                                <p style={{ fontWeight: '200', fontSize: '11px' }}> {item?.ar_country || item?.en_country || "country"} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                    }

                                </div>
                        }
                    </div>
                </div>
                <nav aria-label="Page navigation example">
                    <ul style={{ padding: '0px' }} className="pagination justify-content-center">
                        <li className="page-item disabled">
                            <a className="page-link" tabIndex="-1" aria-disabled="true">
                                <i className={lng === 'arab' ? 'bx bx-chevrons-right bx-fade-right' : 'bx bx-chevrons-left bx-fade-left'}></i>
                            </a>
                        </li>
                        <li className="page-item"><a className="page-link">{t('1')}</a></li>
                        <li className="page-item"><a className="page-link active" >{t('2')}</a></li>
                        <li className="page-item"><a className="page-link" >{t('3')}</a></li>
                        <li className="page-item">
                            <a className="page-link" >
                                <i className={lng === 'arab' ? 'bx bx-chevrons-left bx-fade-left' : 'bx bx-chevrons-right bx-fade-right'}></i>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </section >
    )
}

export default List