import React from 'react'
import { t } from 'i18next'
import { Link } from 'react-router-dom';
import loginImg from '../../../../assets/authBC.png'
import { useDispatch } from 'react-redux';
import { logoutActoin } from '../../../../redux/actions/Auth';
const IsVerf = ({setLoadingModalShow}) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch()
  const logOut = () => {
    dispatch(logoutActoin(setLoadingModalShow))
  }
  return (
    <div className="login_page">
      <img src={loginImg} alt='login_img' className='login_img'/>
      <div className='login_card_wrapper'>
        <h3 className="page_title" style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }}>{t("Account Activation")}</h3>
        <div className='cardd overflow-hidden'>
          <div style={{padding: '20px'}} className="card-body innerCardBody">
        <div className="green-bg" />
        <div  className="login_form" style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }}>
          <div style={{minHeight: '200px', display:'flex', alignItems:'center', justifyContent:'center'}} className='regular_login'>
            <h5 style={{color: 'gray', textAlign:'center'}}>{t("Thanks For Registeration, Admins Are Reviewing Your Account Details Right Now")}</h5>
          </div>
          <div className='haveAnAccount'>
            <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
              <Link style={{fontSize: '11px', color: 'gray'}} to="#" onClick={logOut}>{t('Logout')}</Link>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
  )
}

export default IsVerf