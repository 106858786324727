import React, { useEffect } from 'react'
import ReusableBanner from '../ui/reusableBanner/ReusableBanner'
import SchoolList from '../ui/list/List'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getSchool } from '../../redux/actions/school_Page'

const School = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const dispatch = useDispatch()
  const { school, loading } = useSelector(state => state.school);

  useEffect(() => {
    dispatch(getSchool(lng === "arab" ? 2 : 1))
  }, [dispatch, lng])

  return (
    <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }} className='pages_with_header'>
      <ReusableBanner title={t("SCHOOLS")} bacgroundclassName="schoolBG" />
      <SchoolList {...{school, loading}} search="Search School" type="school" />
    </div>
  )
}

export default School