import { t } from 'i18next';
import React from 'react'
import { useParams } from 'react-router-dom';

const JobOpenings = () => {
  const params = useParams();
  console.log("params",params)
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <div>
      job opening
    </div>
  )
}

export default JobOpenings