import { useTranslation } from 'react-i18next'
import React from 'react'
import Spinner from '../../ui/spinner/Spinner';

export const JobCounter = ({ about, loading }) => {
    const { t } = useTranslation();
    const renderItem = (title, count, icon) => (
        <div className="col-lg-3 col-6">
            <div className="counter-text">
                <i className={icon}></i>
                {!loading ?
                    <>
                        <h2><span>{count || 0}</span></h2>
                    </>
                    :
                    <Spinner />
                }
                <p>{title}</p>
            </div>
        </div>
    )
    return (
        <div className="counter-section pt-100 pb-70">
            <div className="container">
                <div className="row counter-area">
                    {renderItem(t("Job Posted"), about?.data?.jobs, "flaticon-resume")}
                    {renderItem(t("Job Filed"), about?.data?.accepted, "flaticon-recruitment")}
                    {renderItem(t("Academies"), about?.data?.academies, "flaticon-portfolio")}
                    {renderItem(t("Teachers"), about?.data?.teachers, "flaticon-employee")}
                </div>
            </div>
        </div>
    )
}
