import React, { useState, useEffect } from 'react'
import './News.css'
import NewsCom from './newsCom/NewsCom'
import "swiper/swiper-bundle.css";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import Spinner from '../../ui/spinner/Spinner';
import NoDataFound from '../../ui/noDataFound/NoDataFound';

const News = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const [width, setWidth] = useState(window.innerWidth);
    const { article, loading } = useSelector(state => state.article);
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    return (
        <section className="blog-section blog-style-two pt-100 pb-70">
            <div className="container">
                <div className="section-title text-center">
                    <h2>{t("News, Tips & Articles")}</h2>
                    <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus")}</p>
                </div>
                {props.fromNewsPage ?
                    <div style={{ alignItems: 'flex-end', direction: lng === 'arab' ? 'rtl' : 'ltr' }} className="row mb-5">
                        <div className="col-lg-3 col-sm-6">
                            <label className="form-label mb-3">{t("Search Blog")}</label>
                            <div>
                                <input type="text" id="disabledTextInput" className="form-control mb-3" placeholder={t("Keyword")} />
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div>
                                <label className="form-label mb-3">{t("Category")}</label>
                                <select className="form-control mb-3" aria-label="Default select example">
                                    <option>{t("Web Design")}</option>
                                    <option>{t("Job Tips")}</option>
                                    <option>{t("UX Design")}</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div>
                                <label className="form-label mb-3">{t("Tags")}</label>
                                <select className="form-control mb-3" aria-label="Default select example">
                                    <option>{t("Web Design")}</option>
                                    <option>{t("Job Tips")}</option>
                                    <option>{t("UX Design")}</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="d-grid mb-3">
                                <button className="btn" type="submit" style={{ backgroundColor: "#db8307", color: "white" }}>{t("Search")}</button>
                            </div>
                        </div>
                    </div> : null}
                <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }} className="row">
                    {loading ?
                        <Spinner />
                        :
                        article?.data?.length === 0 ?
                            <NoDataFound />
                            :
                            article?.data?.slice(0, props.fromNewsPage ? article?.data?.length : 3).map((item, index) => (
                                <div className="col-lg-4 col-sm-6" key={index}>
                                    <NewsCom data={item} />
                                </div>
                            ))
                    }
                </div>
            </div>
            {props.fromNewsPage ?
                <nav style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }} aria-label="Page navigation example">
                    <ul style={{ padding: '0px' }} className="pagination justify-content-center">
                        <li className="page-item disabled">
                            <a className="page-link" tabIndex="-1" aria-disabled="true">
                                <i className={lng === 'arab' ? 'bx bx-chevrons-right bx-fade-right' : 'bx bx-chevrons-left bx-fade-left'}></i>
                            </a>
                        </li>
                        <li className="page-item"><a className="page-link" >{t('1')}</a></li>
                        <li className="page-item"><a className="page-link active">{t('2')}</a></li>
                        <li className="page-item"><a className="page-link" >{t('3')}</a></li>
                        <li className="page-item">
                            <a className="page-link" >
                                <i className={lng === 'arab' ? 'bx bx-chevrons-left bx-fade-left' : 'bx bx-chevrons-right bx-fade-right'}></i>
                            </a>
                        </li>
                    </ul>
                </nav> : null}
        </section>
    )
}

export default News