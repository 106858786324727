import React from 'react'
import { useTranslation } from 'react-i18next'

const ContactForm = () => {
    const { t } = useTranslation();
    return (
        <section className="contact-form-section pb-100">
            <div className="container">
                <div className="contact-area">
                    <h3>{t("Lets' Talk With Us")}</h3>
                    <form>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" name="name" className="form-control" placeholder={t("Your Name")} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="email" name="email" className="form-control" placeholder={t("Your Email")} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="number" name="number" className="form-control" placeholder={t("Phone Number")} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" name="subject" className="form-control" placeholder={t("Your Subject")} required />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <textarea name="message" className="form-control message-field" cols="30" rows="7" placeholder={t("Write Message")} required ></textarea>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 text-center">
                                <button type="submit" className="default-btn contact-btn">
                                    {t("Send Message")}
                                </button>
                                <div id="msgSubmit" className="h3 alert-text text-center hidden"></div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ContactForm